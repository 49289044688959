import React, {Component} from 'react';
import Rodal from 'rodal';

import './index.css';

import logo from './../../images/logo.png';
import AuthService from "../../modules/authService";
import {
    headerNavId,
    setNavLinksEvent,
} from "../../models/constants";
import locales_es from "./../../locales/es";
import Helpers from "../../modules/helpers";
import APIService from "../../modules/apiService";

export default class Header extends Component {

    constructor(props) {
        super(props);

        this.state = {
            isLoggedIn: false,
            name: '',
            lastname: '',
            // full_profile_image: null,
            fullModal: null,
            venueLogo: '',
            venueName: '',
        };

        this.auth = new AuthService();
        this.helpers = new Helpers();
        this.api = new APIService();
    }

    componentDidMount() {
        // When the component is mounted, add your DOM listener to the "nv" elem.
        // (The "nv" elem is assigned in the render function.)
        this.nav.addEventListener(setNavLinksEvent, () => {
            this.checkUserStatus();
        });
        this.checkUserStatus();


        const isLoggedIn = this.auth.isLoggedUser();
        this.setState({
            isLoggedIn
        }, this.setMenu);

        // this.setMenu();
        this.setVenue();
    }

    setVenue() {
        this.api.getConfig().then(res => {
            this.setState({
                venueLogo: res['logo'],
                venueName: res['venue-name'],
            }, () => this.setHTMLTitle());
        }).catch(err => {
            this.props.showMainModal(locales_es.errorModal.title, this.helpers.getErrorMsg(err));
        });
    }

    setHTMLTitle() {
        const title = document.getElementById('html-title');
        title.innerHTML = this.state.venueName;
    }

    checkUserStatus() {
        this.auth.checkLoginStatusAndDoSomethingOrDefault(this.handleLoginHeader, this.handleLogoutHeader);
    }

    // Use a class arrow function (ES7) for the handler. In ES6 you could bind()
    // a handler in the constructor.
    handleLoginHeader = (event) => {
        this.setState({isLoggedIn: true}, () => this.setMenu());
    };

    handleLogoutHeader = (event) => {
        this.setState({isLoggedIn: false}, () => this.setMenu());
    };

    setMenu() {
        const defaultImageRegex = new RegExp(/1\.png/);
        if (this.state.isLoggedIn) {
            this.auth.getRemoteUserData().then(res => {
                if (res && res.data && res.data.user) {
                    // const profileImg = res.data.full_profile_image;
                    this.setState({
                        name: res.data.user.name,
                        lastname: res.data.user.lastname,
                        // full_profile_image: defaultImageRegex.test(profileImg) ? '' : profileImg,
                    })
                }
            }).catch(err => {
                this.props.showMainModal(locales_es.errorModal.title, this.helpers.getErrorMsg(err));
            });
        }
    }

    showLogoutModal() {
        this.showModal(locales_es.logout, '¿Está seguro de querer cerrar sesión?');
    }

    logout() {
        this.auth.logout(true);
        this.hide();
        // this.toggleMenu();
    }

    loadLoginModal(e) {
        return;
        if (e) {
            e.preventDefault();
        }
        // this.props.history.push('/login');

        this.setState({
            fullModal: 'login'
        }, () => this.show());
    }

    /* MODAL Functions */
    showModal(title, content) {
        this.setState({modalTitle: title});
        this.setState({modalContent: content});
        this.show();
    }

    show() {
        this.setState({visible: true});
    }

    hide() {
        this.setState({visible: false});
    }

    //

    render() {

        const {isLoggedIn, name, lastname, venueLogo, venueName} = this.state;

        return (
            <div id={headerNavId} ref={elem => this.nav = elem}
                 className="mdl-js-layout mdl-layout--fixed-header has-drawer is-upgraded is-small-screen"
                 style={{marginBottom: '30px'}}>

                <div className="navbar-wrapper-header navbar-fixed-top">
                    <div className="container">
                        <a name="top"/>
                        <nav className="main-nav navbar navbar-static-top">
                            <a href="/" className="navbar-brand">
                                {venueLogo && venueName ?
                                    <>
                                        <img src={venueLogo}
                                             className="logo" title={venueName} alt={venueName} height="100%"
                                             align="left"/>
                                        <span className="navbar-brand-name"> {venueName}</span>
                                    </>
                                    : null
                                }
                            </a>
                            <div className="container">
                                <div className="navbar-header">
                                    <button type="button" className="navbar-toggle collapsed"
                                            data-toggle="collapse" data-target="#navbar"
                                            aria-expanded="false" aria-controls="navbar"> <span
                                        className="sr-only">Toggle navigation</span>
                                        <span className="icon-bar"/>
                                        <span className="icon-bar"/>
                                        <span className="icon-bar"/>
                                    </button>
                                </div>
                                <div id="navbar" className="navbar-collapse collapse">
                                    <ul className="nav navbar-nav">
                                        {isLoggedIn ?
                                            <li className="dropdown user" ng-show="user.isLogged">
                                                <a href="#"
                                                   className="dropdown-toggle"
                                                   data-toggle="dropdown"
                                                   role="button" aria-haspopup="true"
                                                   aria-expanded="false">{name || 'Usuario'} {lastname} <span
                                                    className="caret"/></a>
                                                <ul className="dropdown-menu">
                                                    <li><a href="/dashboard"
                                                           data-i18n="nav.dropdown.tickets">Mis Tickets</a></li>
                                                    <li role="separator" className="divider"/>
                                                    <li><a href="/profile"
                                                           data-i18n="nav.dropdown.profile">Mi Perfil</a></li>
                                                    <li><a onClick={() => this.showLogoutModal()}
                                                           data-i18n="nav.dropdown.logout">Cerrar Sesión</a>
                                                    </li>
                                                </ul>
                                            </li>
                                            : <li data-test="nav-login-btn"><a href="/login"
                                                                               onClick={(e) => this.loadLoginModal(e)}>Iniciar
                                                sesión</a></li>
                                        }
                                    </ul>
                                </div>
                            </div>
                        </nav>
                    </div>
                </div>

                <Rodal width={window.screen && window.screen.availWidth ? window.screen.availWidth * 0.9 : '300'}
                       customStyles={this.state.fullModal ? {height: '90%', overflow: 'scroll', zIndex: 9000} : {}}
                       visible={this.state.visible} onClose={() => this.hide()}>
                    <div>
                        <h4 className="rodal-title">{this.state.modalTitle}</h4>
                        <div className="rodal-body">{this.state.modalContent}</div>
                        <div className="rodal-footer">
                            <button className="btn btn-primary" type="button"
                                    onClick={() => this.logout()}>Aceptar
                            </button>
                            <button className="btn btn-alert" type="button"
                                    onClick={() => this.hide()}>Cancelar
                            </button>
                        </div>
                    </div>
                </Rodal>
            </div>
        )
    }
}
