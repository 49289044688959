import React, {Component} from 'react';
import RegisterPage from "../../pages/register";

class RegisterOrLoginRequest extends Component {

  render() {
    return (
      <div>
        <div className="text-center margin-xl">
          <h3 className="margin-sm">Necesitas estar registrado para comprar</h3>
          <button onClick={() => window.showModal()} type="submit" className="btn btn-primary margin-sm">
            Iniciar Sesión o Registrarse
          </button>
        </div>

        <div id="myModal" className="modal fade" tabIndex="-1" role="dialog">
          <div className="modal-dialog" role="document">
            <div className="modal-content">
              <div className="modal-body">
                <RegisterPage modalMode={this.props.modalMode}
                              successMethod={this.props.successMethod}
                              showMainModal={this.props.showMainModal}
                              eventId={this.props.eventId}
                              pathname={this.props.pathname}
                />
              </div>
            </div>
          </div>
        </div>
      </div>
    )
  }
}

export default RegisterOrLoginRequest;
