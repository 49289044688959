import React, {Component} from 'react';
import Header from "../../components/header";
import Loading from "./../../components/loading";
import Spinner from "./../../components/spinner";
import {withFooter} from "../../components/footerHOC";
import APIService from './../../modules/apiService';
import DateTimeService from "../../modules/DateTimeService";

import svgPanZoom from "svg-pan-zoom";

import AuthService from "../../modules/authService";
import RegisterPage from "../../pages/register";
import Review from "../../components/review";
import Helpers from "../../modules/helpers";
import locales_es from "../../locales/es";
import StripeCheckout from "../../components/checkout/stripeCheckout";
import {
    hrefCongratsSuccess,
    SEAT_DISABLED_ATTR,
    SEAT_FILTERED_ATTR,
    SEAT_ID_ATTR,
    SEAT_SECTOR_ID_ATTR,
    SEAT_PRICE_ATTR,
    SEAT_SELECTION_CLASSNAME,
    SEAT_SERVICE_CHARGE,
    seatSelectorId,
    STRIPE,
    MERCADO_PAGO_BASIC,
    SEAT_NAME_ATTR,
    SEAT_FINAL_PRICE_ATTR,
    mapZoomOutBtnClassName,
    mapZoomInBtnClassName, mapFullScreenBtnClassName, MAX_ZOOM_MOBILE
} from "../../models/constants";
import {eventsHandler} from "../../modules/mobileMapEventsHandler";

import './styles.css';
import covidSmartBlockImg from '../../images/nuevo-bloqueo.jpg';
import AnalyticsService from "../../modules/analyticsService";
import CheckoutReview from "../../components/checkoutReview";

class CheckoutMapPage extends Component {

    constructor(props) {
        super(props);
        this.state = {
            loading: false,
            svgLoading: true,
            checkout: null,
            event: this.props.eventData,
            performances: null,
            performanceId: '',
            eventId: Number(props.match.params.id),
            sectors: null,
            sectorId: '',
            seatsIds: '',
            selectedSectorImage: '',
            showReview: false,
            choCartReviewVisible: false,
            coupon_code: '',
        };

        this.api = new APIService();
        this.dateTimeService = new DateTimeService();
        this.auth = new AuthService();
        this.helpers = new Helpers();
        this.analyticsService = new AnalyticsService({
            eventId: this.state.eventId,
            pathname: this.props.location.pathname
        });

        this.confirm = this.confirm.bind(this);
    }

    componentDidMount() {
        this.getPerformances();

        window.refreshToWatch = () => {
            window.setInterval(() => {
                console.log('refreshToWatch');
                this.getPerformances();
            }, 900000); // quince minutos
        };

        this.analyticsService.initGTM().then(() => {
            this.analyticsService.sendGTMEvent('conversion','loadCheckout');
        }).catch(err => {
            console.error(err);
        });
    }

    getPerformances() {
        this.api.getPerformances(this.state.eventId)
            .then((res) => {
                const data = res.data.filter(perf => this.dateTimeService.getTimeRemaining(perf.date).total > 0);
                this.setState({
                    performances: data,
                }, () => {
                    const fakeEvent = {
                        target: {
                            value: data[0].id
                        }
                    };
                    this.onChangePerformance(fakeEvent);
                });
                // setTimeout(() => window.showMainModal(), 3000);
            }).catch(err => {
            this.props.showMainModal(locales_es.errorModal.title, err.message)
        });
    }

    onChangePerformance(e) {
        this.setState({
            performanceId: e.target.value,
        }, () => {
            this.getSectors();
            this.getSvgMap();
            this.setSelectedPerformanceForReviewRenderingOnly();
        });
    }

    setSelectedPerformanceForReviewRenderingOnly() {
        const perf = this.state.performances.filter(p => Number(p.id) === Number(this.state.performanceId))[0];
        this.setState({
            selectedPerformanceForReviewRenderingOnly: perf
        })
    }

    setSelectedSectorForReviewRenderingOnly() {
        const sector = this.state.sectors.filter(s => Number(s.id) === Number(this.state.sectorId))[0];
        this.setState({
            selectedSectorForReviewRenderingOnly: sector
        })
    }

    getSectors() {
        this.setSectorsLoading(true);
        this.setState({
            sectors: null,
        });
        this.api.getSectors(this.state.eventId, this.state.performanceId)
            .then((res) => {
                this.setState({
                    sectors: res.data,
                }, () => this.setSectorsLoading(false));
            }).catch(err => {
            this.props.showMainModal(locales_es.errorModal.title, err.message)
        });
    }

    setSectorsLoading(bool) {
        this.setState({
            sectorLoading: bool
        });
    }

    getSvgMap() {
        this.setSvgLoading(true);
        this.api.getPerformanceMapAsJSON(this.state.performanceId).then(res => {
            this.setSvgLoading(false);
            const el = document.getElementById(seatSelectorId);
            el.innerHTML = res.data;
            this.loadSvgMap();
        }).catch(err => {
            this.props.showMainModal(locales_es.errorModal.title, this.helpers.getErrorMsg(err));
            this.setSvgLoading(false);
        });
    }

    loadSvgMap() {
        try {
            const options = {
                zoomEnabled: !this.helpers.isMobile(),
                controlIconsEnabled: !this.helpers.isMobile(),
                fit: true,
                center: true,
                minZoom: 1,
                maxZoom: this.helpers.isMobile() ? MAX_ZOOM_MOBILE : 3,
                dblClickZoomEnabled: false,
                customEventsHandler: this.helpers.isMobile() ? eventsHandler : undefined,
            };
            window.mapZoom = svgPanZoom('#mainSVG2', options);
            this.addSeatListeners();
            this.addMobileMapZoomListeners();
            this.clear();
        } catch (e) {
            console.log(e);
            this.props.showMainModal(locales_es.errorModal.title, e.message);
        }
    }

    addMobileMapZoomListeners() {
        const map = window.document.getElementById(seatSelectorId);
        const zoomInBtn = window.document.querySelector(`.${mapZoomInBtnClassName}`);
        const zoomOutBtn = window.document.querySelector(`.${mapZoomOutBtnClassName}`);
        const fullscreenBtn = window.document.querySelector(`.${mapFullScreenBtnClassName}`);
        if (this.helpers.isMobile() && map) {
            window.isMobileZoomed = false;

            zoomInBtn.classList.add('show-inline');
            zoomInBtn.addEventListener('click', (e) => {
                if (!window.isMobileZoomed) {
                    e.stopPropagation();
                    window.isMobileZoomed = true;
                    window.mapZoom.zoomBy(MAX_ZOOM_MOBILE);
                    zoomOutBtn.classList.add('show-inline');
                    zoomInBtn.classList.remove('show-inline');
                    fullscreenBtn.classList.add('show-inline');
                }
            }, false);


            zoomOutBtn.addEventListener('click', () => {
                window.mapZoom.zoomBy(0);
                window.isMobileZoomed = false;
                zoomInBtn.classList.add('show-inline');
                zoomOutBtn.classList.remove('show-inline');
                fullscreenBtn.classList.remove('show-inline');
            }, false);

            fullscreenBtn.addEventListener('click', () => {
                map.classList.toggle('eventticket--fullscreen-svg');
                zoomOutBtn.classList.toggle('show-inline');
                // fullscreenBtn.classList.remove('show-inline');
            }, false);
        }
    }

    getAllSeatsAsAnArray() {
        const map = window.document.getElementById(seatSelectorId);
        const seats = map.getElementsByTagName('circle');
        return Array.from(seats);
    }

    addSeatListeners() {
        window.isDragging = false;
        const seats = this.getAllSeatsAsAnArray();

        const mobileDelayMove = 300;

        // convert to Array cause seats is an HTML Collection
        seats.map(seat => {

            if (!this.helpers.isMobile()) {
                // DESKTOP
                seat.addEventListener("mousedown", () => {
                    window.isDragging = false;
                }, false);

                seat.addEventListener("mousemove", () => {
                    window.isDragging = true;
                }, false);

                seat.addEventListener("mouseup", () => {
                    if (!window.isDragging) {
                        this.onSeatSelect(seat);
                    }
                }, false);

            } else {
                // MOBILE
                seat.addEventListener("touchstart", () => {
                    window.isDragging = false;
                    window.draggingLapse = new Date();
                }, false);

                seat.addEventListener("touchmove", () => {
                    if ((new Date() - window.draggingLapse) > mobileDelayMove) {
                        window.isDragging = true;
                    }
                }, false);

                seat.addEventListener("touchend", (e) => {
                    e.preventDefault(); // Woopi: problema que hacía que no se disparar bien el modal de error
                    if (!window.isDragging) {
                        this.onSeatSelect(seat);
                    }
                }, false);
            }

            // TOOLTIP
            const seatStr = locales_es.seat
                + ': '
                + seat.getAttribute(SEAT_NAME_ATTR)
                + ' - '
                + locales_es.price
                + ': '
                + locales_es.currency_sign
                + seat.getAttribute(SEAT_FINAL_PRICE_ATTR);
            seat.setAttribute('data-toggle', 'tooltip');
            seat.setAttribute('data-placement', 'top');
            seat.setAttribute('title', seatStr);
        });
        window.$('[data-toggle="tooltip"]').tooltip({'container': 'body',})
    }

    onSeatSelect(seat) {
        // console.log(seat);
        if (seat.getAttribute(SEAT_DISABLED_ATTR) === 'true') {
            this.props.showMainModal(locales_es.infoModal.title, locales_es.infoModal.disabledSeat);
            return;
        }
        if (seat.getAttribute(SEAT_FILTERED_ATTR) === 'true') {
            this.props.showMainModal(locales_es.infoModal.title, locales_es.infoModal.filteredSeat);
            return;
        }
        seat.classList.toggle(SEAT_SELECTION_CLASSNAME);
        this.filterAvailableSeatsBySector(seat);

        // TEST PHASE...
        this.syncStateWithFrontendView().catch(err => {
            this.props.showMainModal(locales_es.errorModal.title, this.helpers.getErrorMsg(err))
        });
    }

    filterAvailableSeatsBySector(seat) {
        const seats = this.getAllSeatsAsAnArray();
        // Si el asiento quedó elegido
        if (seat.classList.contains(SEAT_SELECTION_CLASSNAME)) {
            seats.map(s => {
                if (s.getAttribute(SEAT_SECTOR_ID_ATTR) !== seat.getAttribute(SEAT_SECTOR_ID_ATTR)) {
                    s.setAttribute(SEAT_FILTERED_ATTR, 'true');
                }
            });
            this.toggleChoCartReviewOrSet(true);
        } else {
            if (!this.hasSelectedSeats() && this.hasFilteredSeats()) {
                this.clearFilteredSeats();
            }
        }
    }

    clearFilteredSeats() {
        const seats = this.getAllSeatsAsAnArray();
        seats.map(s => {
            s.removeAttribute(SEAT_FILTERED_ATTR);
        });
    }

    clearSelectedSeats() {
        const seats = this.getAllSeatsAsAnArray();
        seats.map(s => {
            s.classList.remove(SEAT_SELECTION_CLASSNAME);
        });
    }

    clearSeatsAndSectorState() {
        this.setState({
            sectorId: '',
            seatsIds: '',
        });
    }

    hasFilteredSeats() {
        const seats = this.getAllSeatsAsAnArray();
        return seats.some(seat => seat.getAttribute(SEAT_FILTERED_ATTR) === 'true');
    }

    hasSelectedSeats() {
        const seats = this.getAllSeatsAsAnArray();
        return seats.some(seat => seat.classList.contains(SEAT_SELECTION_CLASSNAME));
    }

    validateForm() {
        // return true; // DEBUG
        if (!this.state.performanceId) {
            this.props.showMainModal(locales_es.errorModal.title, locales_es.errorModal.selectAPerformanceAtLeast);
            return false;
        }

        if (!this.state.sectorId) {
            this.props.showMainModal(locales_es.errorModal.title, locales_es.errorModal.selectASectorAtLeast);
            return false;
        }

        if (!this.state.seatsIds || !this.state.seatsIds.length) {
            this.props.showMainModal(locales_es.errorModal.title, locales_es.errorModal.selectASeatAtLeast);
            return false;
        }

        return true;
    }

    async syncStateWithFrontendView() {
        const seats = this.getAllSeatsAsAnArray();
        const selectedSeats = [];

        seats.map(s => {
            if (s.classList.contains(SEAT_SELECTION_CLASSNAME)) {
                // if (!this.state.sectorId) {
                this.setState({
                    sectorId: s.getAttribute(SEAT_SECTOR_ID_ATTR),
                    sectorPrice: s.getAttribute(SEAT_PRICE_ATTR),
                    serviceCharge: s.getAttribute(SEAT_SERVICE_CHARGE),
                }, this.setSelectedSectorForReviewRenderingOnly);
                // }

                // SEAT
                const seatObj = {
                    id: s.getAttribute(SEAT_ID_ATTR),
                    name: s.getAttribute(SEAT_NAME_ATTR),
                    price: s.getAttribute(SEAT_PRICE_ATTR),
                    serviceCharge: s.getAttribute(SEAT_SERVICE_CHARGE),
                    finalPrice: s.getAttribute(SEAT_FINAL_PRICE_ATTR),
                };
                selectedSeats.push(seatObj);
            }
        });

        console.log(selectedSeats);

        await this.setState({
            seatsIds: selectedSeats
        });
    }

    send() {
        this.syncStateWithFrontendView().then(() => {
            if (this.validateForm()) {
                const objData = {
                    performanceId: this.state.performanceId,
                    sectorId: this.state.sectorId,
                    seatsIds: this.state.seatsIds,
                };
                this.setState({
                    objData
                });
                if (this.auth.isLoggedUser()) {
                    this.setReviewStatus(true)
                } else {
                    window.showModal();
                }
            }
            }
        );

    }

    clear() {
        this.clearFilteredSeats();
        this.clearSelectedSeats();
        this.clearSeatsAndSectorState();
    }

    setReviewStatus(bool) {
        console.log(JSON.stringify(this.state.objData));
        if (bool) {
            const el = document.getElementById(seatSelectorId);
            el.remove();
        }
        this.setState({
            showReview: bool
        }, () => {
            if (!bool) {
                this.getSvgMap();
            }
        });
        this.props.history.push({
            search: `?review=${bool}`
        });

    }

    successMethod() {
        window.hideModal();
        this.setReviewStatus(true)
    }

    getSeatsIds() {
        return this.state.seatsIds.map(seat => {
            return seat.id;
        });
    }

    confirm(totalPrice, coupon_code) {

        this.api.getConfig().then(res => {
            this.setState({
                checkout: res['payment-gateway'],
                stripePublicKey: res['stripe-public-key'],
                totalPrice,
                coupon_code,
            }, () => {
                this.analyticsService.sendGTMEvent('conversion','confirm');
                // TODO mejorar
                setTimeout(() => {
                    if (this.state.checkout === MERCADO_PAGO_BASIC) {
                        this.goToMercadoBasic();
                    }
                }, 2000);
            });
        }).catch(err => {
            console.log(err);
            this.props.showMainModal(locales_es.errorModal.title, locales_es.errorModal.apiGetConfigError);
        });

    }

    renderStripeCheckout() {
        const data = {
            event_id: this.state.eventId,
            event_performance_id: this.state.performanceId,
            sector_id: this.state.sectorId,
            seats_ids: this.getSeatsIds(),
        };

        const success = () => {
            this.props.showMainModal(locales_es.checkoutSuccessModal.title, locales_es.checkoutSuccessModal.subtitle);
            this.props.history.replace(hrefCongratsSuccess)
        };

        const error = () => {
            this.props.showMainModal(locales_es.checkoutErrorModal.title, locales_es.checkoutErrorModal.subtitle);
        };

        window.stripeKey = this.state.stripePublicKey;

        return (
            <StripeCheckout stripePublicKey={this.state.stripePublicKey} totalPrice={this.state.totalPrice} data={data}
                            onSuccess={success} onError={error}/>
        )
    }

    goToMercadoBasic() {
        const data = {
            event_id: this.state.eventId,
            event_performance_id: this.state.performanceId,
            sector_id: this.state.sectorId,
            seats_ids: this.getSeatsIds(),
            coupon_code: this.state.coupon_code,
        };

        this.api.postCheckout(data).then(res => {
            window.location.href = res.data.init_point;
        }).catch(err => {
            this.props.showMainModal(locales_es.errorModal.title, this.helpers.getErrorMsg(err));
            this.setState({
                checkout: null, // reset CHO
            });
        });
    }

    cancelCheckout() {
        this.setReviewStatus(false);
        this.setState({
            checkout: null,
            seatsIds: ''
        }, () => this.getPerformances());
    }

    setLoading(bool) {
        this.setState({
            loading: bool
        });
    }

    setSvgLoading(bool) {
        this.setState({
            svgLoading: bool
        });
    }

    toggleChoCartReviewOrSet(bool) {
        this.setState({
            choCartReviewVisible: bool || !this.state.choCartReviewVisible
        })
    }

    renderSectors() {
        return (
            this.state.sectors && !this.state.sectorLoading ?
                this.state.sectors.map(sector => {
                    return (
                        <p key={sector.id}>
                            <span className="eventticket-reference-color" style={{backgroundColor: sector.color}}/>
                            {sector.name} {locales_es.currency_sign}
                            {sector.final_price}
                            {sector.sold ? ' (AGOTADO)' : ''}
                        </p>
                    )
                })
                : <Spinner/>
        )
    }

    render() {

        const {
            event,
            performances,
            showReview,
            checkout,
            seatsIds,
            sectorPrice,
            serviceCharge,
            selectedPerformanceForReviewRenderingOnly,
            selectedSectorForReviewRenderingOnly,
        } = this.state;

        return (
            <>
                {this.state.loading ? <Loading/> : ''}
                <Header showMainModal={this.props.showMainModal}/>

                {checkout ?
                    <div>
                        <div className="container">
                            <a onClick={() => this.cancelCheckout()}
                               data-i18n="registerLink"> {locales_es.goBack}</a>
                        </div>
                        {this.state.checkout === STRIPE ? this.renderStripeCheckout() : <Spinner/>}
                    </div>
                    :
                    showReview && event ?
                        <>
                            <div className="container">
                                <a onClick={() => this.cancelCheckout()}
                                   data-i18n="registerLink"> {locales_es.goBack}</a>
                            </div>
                            <Review eventId={this.state.eventId}
                                    image={event.full_image}
                                    title={event.title}
                                    theater={event.theater}
                                    performanceId={this.state.performanceId}
                                    objData={this.state.objData}
                                    sectorPrice={this.state.sectorPrice || 100} // TODO DEBUG
                                    serviceCharge={this.state.serviceCharge || 10} // TODO DEBUG
                                    confirm={this.confirm}
                                    showMainModal={this.props.showMainModal}
                            />
                        </>
                        :
                        <div>

                            {event ?
                                <div>
                                    <div
                                        className="event-profile__subheader event-profile__subheader-desktop hidden-xs hidden-sm">
                                        <div className="container">
                                            <div className="row"><h1 className="ng-binding">{event.title}</h1></div>
                                        </div>
                                    </div>
                                    <div className="container">
                                        <div className="row event-profile">
                                            <div className="col-sm-12 col-md-6">
                                                <h4 className="reference-labels__title"><strong
                                                    data-i18n="bookingMap.mainTitle">Selección
                                                    de Asientos</strong></h4>
                                                <div className="row">
                                                    <h4>Elegir función</h4>
                                                    {performances ?
                                                        <select
                                                            className="form-control"
                                                            onChange={(e) => this.onChangePerformance(e)}
                                                            value={this.state.performanceId}
                                                            disabled={this.state.svgLoading}
                                                            required="required">
                                                            {performances.map(perf => {
                                                                return (
                                                                    <option value={perf.id} key={'perf' + perf.id}>
                                                                        {this.dateTimeService.parseEventDate(perf.date, false, 'full-string')} {this.dateTimeService.parseEventTime(perf.date, 'full-string')}
                                                                    </option>
                                                                )
                                                            })
                                                            }
                                                        </select>
                                                        :
                                                        <Spinner/>
                                                    }
                                                    <br/>
                                                </div>
                                            </div>


                                            <div className="col-sm-12 col-md-6 hidden-xs hidden-sm">
                                                <h4 className="reference-labels__title">
                                                    <strong
                                                        data-i18n="bookingMap.title">{locales_es.referencesAndPrices}</strong>
                                                </h4>
                                                <div className="row">
                                                    <div className="col">
                                                        {this.renderSectors()}
                                                    </div>
                                                </div>
                                            </div>
                                            <div className="col-sm-12 col-md-6 hidden-md hidden-lg">
                                                <button className="form-control" type="button" data-toggle="collapse"
                                                        data-target="#collapseExample" aria-expanded="false"
                                                        aria-controls="collapseExample">
                                                    {locales_es.referencesAndPrices} <span className="caret" />
                                                </button>
                                                <div className="collapse" id="collapseExample">
                                                    <br />
                                                    {this.renderSectors()}
                                                </div>
                                            </div>
                                        </div>
                                    </div>


                                    <div className="container" style={{marginBottom: '10vh'}}>
                                        <div className="row event-profile">
                                            <div className="col-sm-12">
                                                <h4>Elige los asientos</h4>
                                                <div className="row eventticket-cho-map">
                                                    {this.state.svgLoading ? <Spinner/> :
                                                        <div id={seatSelectorId} style={{overflow: 'hidden'}}/>
                                                    }
                                                    <span className={mapZoomInBtnClassName}>
                                                        <span>{locales_es.tapToChoose}</span>
                                                    </span>
                                                    <span className={mapZoomOutBtnClassName}>
                                                        <i className="fas fa-search-minus"/>
                                                    </span>

                                                    <span className={mapFullScreenBtnClassName}>
                                                        <i className="fas fa-expand"/>
                                                    </span>
                                                </div>
                                            </div>
                                        </div>
                                    </div>

                                    {!this.state.svgLoading && <CheckoutReview
                                            seatsIds={seatsIds}
                                            choCartReviewVisible={this.state.choCartReviewVisible}
                                            sectorPrice={sectorPrice}
                                            serviceCharge={serviceCharge}
                                            date={selectedPerformanceForReviewRenderingOnly
                                                ? this.dateTimeService.parseEventDate(selectedPerformanceForReviewRenderingOnly.date, false, 'full-string') + ' ' + this.dateTimeService.parseEventTime(selectedPerformanceForReviewRenderingOnly.date, 'full-string')
                                                : ''}
                                            sectorName={selectedSectorForReviewRenderingOnly ? selectedSectorForReviewRenderingOnly.name : ''}
                                            clear={() => this.clear()}
                                            send={() => this.send()}
                                            toggleChoCartReviewOrSet={() => this.toggleChoCartReviewOrSet()}
                                        />
                                    }


                                </div>
                                :
                                <Loading/>
                            }


                            <div id="myModal" className="modal fade" tabIndex="-1" role="dialog">
                                <div className="modal-dialog" role="document">
                                    <div className="modal-content">
                                        <div className="modal-body">
                                            <RegisterPage modalMode={true}
                                                          successMethod={() => this.successMethod()}
                                                          showMainModal={this.props.showMainModal}
                                                          eventId={this.state.eventId}
                                                          pathname={this.props.location.pathname}
                                            />
                                        </div>
                                    </div>
                                </div>
                            </div>

                            <div id="myMainModal" className="modal fade" tabIndex="-1" role="dialog">
                                <div className="modal-dialog" role="document">
                                    <div className="modal-content">
                                        <div className="modal-header text-center">
                                            <h1>Comprá con tranquilidad</h1>
                                        </div>
                                        <div className="modal-body text-center">
                                            <img width="100%" src={covidSmartBlockImg}/>
                                            <br/>
                                            <p className="margin-x-md"><strong>Estamos tomando todas las medidas de
                                                seguridad necesarias para que se respete el protocolo
                                                sanitario.</strong></p>
                                            <p>Cuando hacés una compra, el sistema bloquea los asientos aledaños para
                                                respetar el distanciamiento social</p>
                                        </div>
                                        <div className="modal-footer text-center">
                                            <button type="button" className="btn btn-primary"
                                                    data-dismiss="modal">{locales_es.accept}
                                            </button>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                }

            </>
        )
    }
}

export default withFooter(CheckoutMapPage);
