export default {
	"buyTickets": "Comprar Tickets",
	"performance": "Función",
	"area": "Área",
	"chooseArea": "Elegir área",
	"sector": "Sector",
	"seatsPosition": "Ubicación",
	"referencesAndPrices": "Referencias y Precios",
	"mantenainceMode": "Estamos trabajando en el sitio. Por favor, accede más tarde para seguir con tus consultas.",
	"selectTicketsQuantity": "Indica cantidad de tickets",
	"chooseSeats": "Elige tus asientos en el mapa",
	"tapToChoose": "Toca para elegir",
	"buy": "Comprar",
	"noStock": "Agotado",
	"noPresentationAvailable": "No hay funciones disponibles",
	"seat": "Asiento",
	"seats": "Asientos",
	"tickets": "Tickets",
	"selectedSeats": "Asientos seleccionados",
	"noSelectedSeats": "Sin asientos seleccionados",
	"total": "Total",
	"orderReview": "Resumen de la compra",
	"notDefined": "Sin ingresar",
	"name": "Nombre",
	"lastname": "Apellido",
	"back": "Volver",
	"close": "Cerrar",
	"selectValue": "Seleccione...",
	"clickToPhoneCall": "Haz click para llamar",
	"editProfile": "EDITAR PERFIL",
	"searchTypeSelector": {
		"searchByBrand": "Buscar por Marca",
		"searchByDrugs": "Buscar por Droga",
	},
	"global": {
		"errorTitle": "Error"
	},
	"recovery": "Recuperar Contraseña",
	"register": {
		"button": "Registrarme",
		"errorName": "Ingrese un nombre",
		"errorEmail": "El E-Mail es inválido",
		"errorSpecialtyMissing": "Seleccione al menos una especialidad",
		"errorPassword": "Ingrese una contraseña",
		"errorTerms": "Debe aceptar los Términos y Condiciones",
		"errorTransferCategoryName": "Debe ingresar un nombre",
		"errorProfileImageMissing": "Debe añadir una foto suya",
		"errorPasswordRepeatNotMatched": "La contraseña y la contraseña repetida no coinciden",
	},
	"menu": {
		"home": "Inicio",
		"configuration": "Configuración",
		"createPrescriptions": "Generar Recetas",
		"myMedics": "Mis Especialistas",
		"myChildren": "Mis Hijos",
		"myPayments": "Mis Medios de Pago",
		"myConsultations": "Mis Consultas",
		"consultations": "Consultas del Paciente",
		"myPatients": "Mis Pacientes",
		"myMovements": "Mis Movimientos",
		"myAppointments": "Mis Turnos",
		"FAQsCategories": "Preguntas Frecuentes",
	},
	"unassignMedicConfirmModal": {
		"unassignMedicTitle": "¿Está seguro de desvincularse de este médico?",
		"unassignMedicSubtitle": "Confirme que desea desvincular este médico",
	},
	"unassignChildMedicConfirmModal": {
		"unassignMedicTitle": "¿Está seguro de desvincular este médico de su hijo?",
		"unassignMedicSubtitle": "Confirme que desea desvincular este médico",
	},
	"myChildren": {
		"removeChildTitle": "¿Está seguro de remover este hijo?",
		"removeChildSubtitle": "Confirme que desea remover este hijo",
	},
	"myConsultations": {
		"removeConsultationTitle": "¿Está seguro de eliminar esta consulta?",
		"removeConsultationSubtitle": "Confirme que desea elimina esta consulta",
	},
	"myPayments": {
		"removePaymentTitle": "¿Está seguro de eliminar este medio de pago?",
		"removePaymentSubtitle": "Confirme que desea elimina este medio de pago",
	},
	"indicationsList": {
		"removeIndicationTitle": "¿Está seguro de eliminar esta indicación?",
		"removeIndicationSubtitle": "Recuerde que el paciente ya no podrá verla",
	},
	"signaturesList": {
		"removeSignatureTitle": "¿Está seguro de eliminar esta firma?",
		"removeSignatureSubtitle": "Recuerde que los pacientes ya no podrán verla ni estará disponible para firmar los documentos",
	},
	"stampsList": {
		"removeStampTitle": "¿Está seguro de eliminar este sello?",
		"removeStampSubtitle": "Recuerde que los pacientes ya no podrán verlo ni estará disponible para sellar los documentos",
	},
	"medicinesList": {
		"removeMedicineTitle": "¿Está seguro de quitar esta medicina de la receta actual?",
		"removeMedicineSubtitle": "Esta acción no puede deshacerse",
	},
	"prescriptionsList": {
		"removePrescriptionTitle": "¿Está seguro de eliminar esta receta?",
		"removePrescriptionSubtitle": "Recuerde que el paciente ya no podrá verla",
	},
	"medicalOrdersList": {
		"removeMedicalOrderTitle": "¿Está seguro de eliminar esta órden?",
		"removeMedicalOrderSubtitle": "Recuerde que el paciente ya no podrá verla",
	},
	"medicalCertificatesList": {
		"removeMedicalCertificateTitle": "¿Está seguro de eliminar este certificado?",
		"removeMedicalCertificateSubtitle": "Recuerde que el paciente ya no podrá verlo",
	},
	"viewConsultation": {
		"confirmConsultationTitle": "¿Está seguro de finalizar esta consulta?",
		"confirmConsultationSubtitle": "Confirme que desea finalizar esta consulta",
	},
	"imagePicker": {
		"title": "Cambiar foto de perfil...",
		"cancelButtonTitle": "Cancelar",
		"takePhotoButtonTitle": "Sacar una foto",
		"chooseFromLibraryButtonTitle": "Elegir de la galería de fotos",
	},
	"consultationStatus": {
		"created": "Creada",
		"confirmed": "Confirmada",
		"completed": "Completada",
		"canceled": "Cancelada",
		"rejected": "Rechazada",
		"payment_rejected": "Pago Rechazado",
		"pending_payment": "Pago Pendiente",
	},
	"paymentStatus": {
		"approved": "Aprobada",
		"pending": "Pendiente de revisión",
		"rejected": "Rechazada",
		"tokenized": "Pendiente",
		"payment_rejected": "Pago Rechazado",
		"pending_payment": "Pago Pendiente",
	},
	"movementStatus": {
		"completed": "Aprobado",
		"approved": "Aprobada",
		"pending": "Pendiente",
		"rejected": "Rechazada",
		"tokenized": "Procesando medio de pago",
		"payment_rejected": "Pago Rechazado",
		"pending_payment": "Pago Pendiente",
	},
	"possibleAssignmentPatientsTitle": "Pacientes que puedes vincular",
	"noAvailablePatientsToAssign": "No tienes pacientes para vincular con este médico",
	"myChildrenMedics": "Médicos de tus hijos",
	"adultsEnabled": "Atiende adultos",
	"childsEnabled": "Atiende niños",
	"specialty": "Especialidad",
	"specialties": "Especialidades",
	"retry": "Reintentar",
	"issuedBy": "emitida por",
	"checkingYourPayments": "Comprobando sus medios de pago",
	"thisCouldTakeALong": "Esto puede demorar un poco...",
	"cardEndingIn": "Tarjeta terminada en",
	"cantUnassignMedicActiveConsultation": "No puedes desvincularte con un médico mientras tengas una consulta activa",
	"select": "Seleccionar",
	"bookAppointment": "Solicitar Turno",
	"myProfile": "Mi Perfil",
	"myAppointments": "Mis Turnos",
	"seeAppointments": "Ver Turnos",
	"appointment": "Turno",
	"appointments": "Turnos",
	"appointmentsFuture": "Turnos",
	"appointmentsPast": "Turnos Pasados",
	"hour": "Hora",
	"status": "Estado",
	"chat": "Chat",
	"weight": "Peso en kgs.",
	"patientWeight": "Peso del paciente en kgs.",
	"consultationReasonTitle": "Motivo de la consulta",
	"consultationDescriptionTitle": "Descripción",
	"consultationInformation": "Información de consulta",
	"consultationPrice": "Precio de la consulta",
	"paymentOption": "Medio de pago",
	"freeConsultation": "Consulta sin cargo",
	"patientData": "Datos del paciente",
	"medicToAddConsultation": "Médico a consultar",
	"remove": "Quitar",
	"identificationType": "Tipo de Identificación",
	"accountType": "Tipo de Cuenta",
	"birth_date": "Fecha de nacimiento",
	"parentRole": "Rol con tu hijo",
	"presentation": "Presentación",
	"gender": "Género",
	"save": "Guardar",
	"clean": "Limpiar",
	"send": "Enviar",
	"initConsultation": "Iniciar consulta",
	"seeActiveConsultation": "Ver consulta activa",
	"emptySearch": "Ingrese un texto para buscar",
	"notEnoughSearch": "Ingrese más de 3 caracteres para iniciar la búsqueda",
	"notEnoughChildren": "Debe ingresar los datos de su hijo.",
	"addYourFirstChild": "Ingresa ahora los datos de tu hijo.",
	"search": "Buscar...",
	"signatures": "Firmas digitales",
	"signature": "Firma digital",
	"stamps": "Sellos personales",
	"stamp": "Sello personal",
	"clarifies": "Aclaraciones del medicamento...",
	"searchPrescription": "Buscar medicina...",
	"noChildren": "No tienes hijos ingresados en el sistema.",
	"noChildrenByPatient": "Este paciente no tienes hijos ingresados en el sistema.",
	"noIndication": "Esta consulta no tiene indicaciones.",
	"noSignature": "Usted no tiene ninguna firma digital configurada.",
	"noStamp": "Usted no tiene ningún sello configurado.",
	"noMedicines": "Esta receta no tiene medicinas añadidas.",
	"noPrescription": "Esta consulta no tiene recetas.",
	"noMedicalOrder": "Esta consulta no tiene órdenes.",
	"noConsultation": "No tienes consultas en el sistema.",
	"noActiveConsultation": "No tienes consultas activas por el momento.",
	"noFAQs": "No hay preguntas frecuentes disponibles.",
	"noPayments": "No tienes medios de pago activos.",
	"paymentGatewayEcuador": "Pagos Procesados por PlaceToPay",
	"noMedicalCertificates": "Esta consulta no tiene certificados.",
	"noMovements": "Sin movimientos hasta el momento.",
	"noFutureAppointments": "Sin turnos futuros.",
	"noPastAppointments": "Sin turnos pasados.",
	"addYourChildren": "Ingresa tu hijo a la plataforma ahora",
	"addAnIndication": "Ingresa una indicación para el paciente",
	"addASignature": "Añade una firma digital para tus documentos",
	"addAStamp": "Añade un sello para tus documentos",
	"addAMedicine": "Añade una medicina para el paciente",
	"addAPrescription": "Ingresa una receta para el paciente",
	"requestAPrescription": "Solicitala a tu médico a través del chat",
	"patientMissing": "Selecciona un paciente para adjuntar esta receta",
	"addAnIndicationAtLeast": "Ingresa al menos una indicación para el paciente",
	"addAnMedicalOrder": "Ingresa una órden para el paciente",
	"requestAMedicalOrder": "Solicitala a tu médico desde el chat",
	"addAMedicalCertificate": "Ingresa un certificado para el paciente",
	"requestAMedicalCertificate": "Solicitalo a tu médico a través del chat",
	"addAnMedicalOrderAtLeast": "Ingresa al menos una órden para el paciente",
	"addYourConsultation": "Ingresa tu primera consulta",
	"addAPaymentMethod": "Agrega un medio de pago ahora.",
	"addPaymentMethod": "Agregar medio de pago",
	"availabilityInfoAboutMedicalDirectory": "Encender esta funcionalidad implica que los usuarios pacientes podrán encontrarte en la cartilla médica de Ánima e iniciar consultas médicas contigo.",
	"message": "Mensaje",
	"addChild": "Añadir hijo",
	"addConsultation": "Iniciar consulta",
	"consultation": "Consulta",
	"addPrescription": "Añadir receta",
	"addIndication": "Añadir indicación",
	"removeIndication": "Remover indicación",
	"addSignature": "Añadir firma digital",
	"removeSignature": "Remover firma digital",
	"writeStamp": "Escribe aquí tu sello personal",
	"addStamp": "Añadir sello",
	"removeStamp": "Remover sello ",
	"addMedicine": "Añadir medicina",
	"maxMedicineReached": "Se permiten 3 medicinas máximo",
	"addAnotherMedicine": "Añadir más medicina",
	"removeMedicine": "Remover medicina",
	"seePrescription": "Ver Receta",
	"addEmailField": "Añadir campo de E-mail",
	"removeEmailField": "Remover campo de E-mail",
	"editIndication": "Editar indicación",
	"editSignature": "Editar firma digital",
	"editPrescription": "Editar receta",
	"addMedicalOrders": "Añadir Órdenes Médicas",
	"diagnosis": "Diagnóstico",
	"icd10Title": "Diagnóstico (Opcional)",
	"icd10AddTitle": "Buscar Diagnóstico",
	"icd10AddSuggestedTitle": "Diagnósticos Sugeridos",
	"icd10Search": "Ingresa un texto para buscar...",
	"icd10AddButton": "Agregar diagnóstico",
	"icd10RemoveButton": "Quitar diagnóstico",
	"code": "Código",
	"indications": "Indicaciones",
	"indication": "Indicación",
	"prescriptions": "Recetas",
	"quantity": "Cantidad",
	"thisDoctorIsCallingYou": "Este médico está queriendo conectarse contigo",
	"medicines": "Medicinas",
	"prescription": "Receta",
	"medical_orders": "Órdenes de Estudio",
	"medical_order": "Órden de Estudio",
	"noSelectedMedicalOrdersTemplates": "No has seleccionado ningún ítem. Debes seleccionar al menos uno para poder continuar.",
	"addMedicalOrder": "Añadir órden",
	"removeMedicalOrder": "Remover órden",
	"editMedicalOrder": "Editar órden",
	"medical_certificates": "Certificados",
	"medical_certificate": "Certificado",
	"noSelectedMedicalCertificatesTemplates": "Debes seleccionar uno para poder continuar.",
	"addMedicalCertificate": "Añadir certificado",
	"removeMedicalCertificate": "Remover certificado",
	"selectMedicalCertificateTemplate": "Seleccione Certificado",
	"editMedicalCertificate": "Editar certificado",
	"viewMedicalCertificate": "Ver certificado",
	"viewDocument": "Ver documento",
	"inactiveConsultation": "Consulta Inactiva",
	"completeConsultation": "Finalizar Consulta",
	"youGot": "Tienes",
	"acceptVideocall": "Aceptar Videollamada",
	"rejectedVideocallByPatient": "Llamada rechazada. Le avisaremos a tu médico que no puedes atenderlo en estos momentos.",
	"rejectVideocall": "Rechazar Videollamada",
	"initVideocall": "Iniciar Videollamada",
	"joinVideocall": "Unirse a la Videollamada",
	"endedVideocallError": "Esta videollamada ha terminado. El médico puede iniciar una nueva si fuese necesario.",
	"loadingVideocall": "Cargando videollamada...",
	"enteringVideocall": "Ingresando a la videollamada...",
	"endingVideocall": "Cerrando videollamada...",
	"goToChat": "Ir al Chat",
	"requestVaccines": "Solicitar Vacunas",
	"requestCertificates": "Solicitar Certificados",
	"certificates": "Certificados",
	"createIndication": "Crear Indicación",
	"createMedicalOrder": "Crear Órden",
	"createCertificate": "Crear Certificado",
	"requestAppointment": "Solicitar Turno",
	"createAppointment": "Crear Sobreturno",
	"createExamRequest": "Crear Orden de Estudio",
	"requestExam": "Solicitar Órden de Estudio",
	"exams": "Órdenes de Estudio",
	"requestRecipe": "Solicitar Receta",
	"recipes": "Recetas",
	"createRecipe": "Crear Receta",
	"addFAQ": "Enviar FAQ",
	"orYouCanSearchInOurMedicalDirectory": "O puedes buscar un médico en nuestra cartilla...",
	"searchAnotherMedicInTheMedicalDirectory": "Buscar otro médico en cartilla",
	"typeToSearchYourMedic": "Ingresa el nombre de tu médico de cabecera para encontrarlo en Ánima",
	"typeToSearch": "Ingresa un texto de búsqueda",
	"typeAndSearch": "Ingresa un texto y apreta buscar",
	"addMedicinesToGetICD10Results": "Ingresa medicinas a tu receta para encontrar aquí los ICD10 relacionados",
	"ICD10ResultsNotFound": "No se encontraron ICD10 relacionados a las medicinas que indicaste",
	"ICD10SearchNotFound": "No se encontraron ICD10 relacionados a tu búsqueda.",
	"searchNotFound": "No se encontraron resultados",
	"children": "Hijos/Hijas",
	"child": "Hijo/a",
	"credit_card_debit": "Tarjeta de crédito/débito",
	"pediatrician": "Médico",
	"patient": "Paciente",
	"medic": "Médico",
	"medics": "Médicos",
	"confirmAssignMedicTitle": "¿Asignar este médico?",
	"confirmAssignMedicSubtitle": "Acepta para vincular y hacer consultas con este médico",
	"confirmUnassignMedicTitle": "¿Desvincular este médico?",
	"confirmUnassignMedicSubtitle": "¿Desea desvincularse de este médico? Ya no podrá realizar consultas con este médico.",
	"noChildrenMedicsAssigned": "Tus hijos no tienen médicos vinculados",
	"noMedicsAssigned": "No tienes médicos vinculados",
	"assignThisMedic": "Vinculate con este médico",
	"unassignThisMedic": "Desvincular médico",
	"cantCreateConsultationActiveConsultation": "No puedes crear una consulta nueva mientras tengas otra consulta todavía activa.",
	"selectAtLeastAPatientToAsignMedic": "Selecciona al menos un paciente para vincular con el médico seleccionado",
	"selectMedic": "Selecciona un médico",
	"selectPatient": "Selecciona un paciente",
	"assignMedic": "Vincular médico",
	"assignedMedic": "Médico Vinculado",
	"assignedMedics": "Médicos Vinculados",
	"assignMoreMedic": "Vincula más médicos",
	"assignMoreMedic2": "Puedes vincular médicos de distintas especialidades y hacer consultas para usted y/o sus hijos",
	"medicAssignedSuccesfully": "Médico Vinculado con Éxito",
	"unassignMedic": "Desvincular a tú médico",
	"updateAppTitle": "Debe actualizar la aplicación para poder continuar.",
	"updateAppMessage": "Su versión instalada es demasiado antigua.",
	"updateText": "Actualizar",
	"loading": "Cargando",
	"uploadingImage": "Subiendo imagen...",
	"whatsappShareInvitationTitle": "Invita pacientes nuevos",
	"whatsappShareInvitationTitlePatients": "Invita a tú medico",
	"whatsappShareInvitationSubtitle": "Enviales una invitación para vincularse contigo a través de Ánima",
	"whatsappShareInvitationButtonText": "Invitar por WhatsApp",
	"whatsappShareButtonText": "Compartir por WhatsApp",
	"whatsapp_share_text_intro_patients": "*Hola, soy",
	"whatsapp_share_text_intro": "*Soy tu médico/a",
	"whatsapp_share_text": " recibo consultas como las tuyas vía WhatsApp a diario... pero *ahora uso ÁNIMA*, una herramienta diseñada *especialmente para consultas médicas*. Por favor escríbeme directo por esta app. Es más segura, y hace que *toda tu información*, como la de todos mis pacientes esté *resguardada y ordenada*.\n\n Te invito a usarla descargándola para Android desde https://play.google.com/store/apps/details?id=com.animarn.animamedica o para iPhone desde https://apps.apple.com/ar/app/%C3%A1nima-ecuador/id1487244370?l=es. \n\n *Además* te puedo generar *recetas online* en cualquier momento, que te servirán para *reembolso* con tu aseguradora.\n\n Puedes ver mas info aquí: http://www.appanima.com",
	"whatsapp_share_text_patients": " *Te invito a usar ÁNIMA*, una herramienta diseñada *especialmente para consultas médicas*. Es más segura, y hace que *toda nuestra información* esté *resguardada y ordenada*.\n\n Te invito a usarla descargándola para Android desde https://play.google.com/store/apps/details?id=com.animarn.animamedica o para iPhone desde https://apps.apple.com/ar/app/%C3%A1nima-ecuador/id1487244370?l=es. \n\n *Además* podemos generar *recetas online* en cualquier momento, que servirán para *reembolso* en nuestra aseguradora.\n\n Puedes ver mas info aquí: http://www.appanima.com",
	"whatsapp_share_text_success": "Gracias por invitar a tus pacientes a unirse",
	"whatsapp_share_text_error": "No se pudo compartir por WhatsApp, pero aún puedes invitar a tus pacientes a descargar la aplicación desde http://appanima.com/, a buscarte y a contactarte directamente.",
	"shareChatTitle": "Comparte por chat",
	"shareChatSubtitle": "Elige el chat de la consulta en la que quieras compartir",
	"completeAllFields": "Complete todos los campos",
	"confirmLogoutModal": {
		"title": "¿Está seguro?",
		"subtitle": "¿Quiere cerrar sesión?",
		"accept": "Salir",
		"cancel": "Cancelar"
	},
	"successModal": {
		"title": "Éxito",
	},
	"errorModal": {
		"title": "Error",
		"checkData": "Revise sus datos",
		"accept": "Aceptar",
		"apiGetConfigError": "Hubo un error al obtener la configuración del evento",
		"selectAPerformanceAtLeast": "Elija la función a la que quiere asistir",
		"selectASectorAtLeast": "Elija el sector deseado",
		"selectASeatAtLeast": "Elija al menos un asiento",
		"cantBuyMoreTickets": "No se pueden comprar más asientos de los disponibles",
		"availableTicketsForThisSector": "Tickets disponibles hasta el momento para este sector",
		"chooseDesiredQuantity": "Elija la cantidad deseada",
		"enterACoupon": "Ingresa el cupón en el campo de texto correspondiente",
	},
	"checkoutSuccessModal": {
		"title": "Éxito",
		"subtitle": "La compra ha sido exitosa",
	},
	"checkoutErrorModal": {
		"title": "Error",
		"subtitle": "Ha ocurrido un error al procesar su pago. Revise sus datos.",
	},
	"infoModal": {
		"title": "Información",
		"checkData": "Revise sus datos",
		"accept": "Aceptar",
		"disabledSeat": "Este asiento no está disponible. Por favor, elija otro.",
		"filteredSeat": "No puede elegir asientos de distintos sectores.",
		"disabledArea": "Esta área ya no está disponible. Por favor, elija otra.",
	},
	"quotationExpiredErrorModal": {
		"title": "Error",
		"subtitle": "Ha pasado demasiado tiempo para confirmar este viaje. Por favor, solicite un viaje nuevo.",
		"accept": "Aceptar"
	},
	"userDeniedGeolocationErrorModal": {
		"title": "No se pudo obtener su ubicación",
		"subtitle": "Debe conceder permisos de geolocalización a nuestra app para poder hacer uso de esta funcionalidad",
		"accept": "Aceptar"
	},
	"functionNotGuaranteed": "El correcto funcionamiento de esta prestación no está garantizado. Por favor, revise los permisos de la aplicación",
	"age": "Edad",
	"year": "Año",
	"years": "Años",
	"month": "Mes",
	"months": "Meses",
	"day": "Día",
	"days": "Días",
	"role": "Rol",
	"no_patients": "No tienes pacientes vinculados",
	"enjoy_ride": "¡Disfruta del viaje!",
	"wait_please": "Espere, por favor",
	"domain": "Patente",
	"driver_waiting_at_origin": "El chófer está esperandote",
	"currency_sign": "$",
	"hotErrorMsg": "Envía un e-mail al administrador del sitio si no consigues finalizar tu compra con éxito",
	"price": "Precio",
	"setPriceToContinueEvenZero": "Configure un precio para continuar, aunque sea $0",
	"quotate_trip": "Cotizar Viaje",
	"google_directions_failed": "La solicitud de direcciones falló por lo siguiente:",
	"select_origin": "Selecciona origen del viaje",
	"country": "País",
	"accept": "Aceptar",
	"username": "Nombre de Usuario",
	"phone_number": "Teléfono",
	"password": "Contraseña",
	"repeatPassword": "Repetir Contraseña",
	"changePassword": "Cambiar Contraseña",
	"login": "Iniciar sesión",
	"new_user": "¿Usuario nuevo?",
	"sign_up": "Registrarse",
	"sign_in": "Iniciar sesión",
	"forgot": "¿Olvidaste",
	"yourPassword": "tu contraseña?",
	"or_continye_with": "O CONTINÚA CON",
	"facebook": "Facebook",
	"google": "Google",
	"first_name": "Nombre",
	"email_address": "E-Mail",
	"last_name": "Apellido",
	"preexisting_diseases": "Antecedentes. Ej.: alergias, etc.",
	"takes_medication": "¿Toma alguna medicación?",
	"preexisting_medication_by_comma": "Remedios separados por coma",
	"preexisting_medication": "¿Toma alguna medicación?",
	"preexistences": "Antecedentes",
	"medication": "Medicación",
	"resendDocument": "Reenviar Documento",
	"identification": "Cédula / Pasaporte",
	"create_password": "Generar Contraseña",
	"confirm_password": "Confirmar Contraseña",
	"sign_up_now": "Registrarse ahora",
	"already_registered": "¿Ya tienes usuario?",
	"verification": "Verificación",
	"next": "Siguiente",
	"enter_confirmation_code": "Ingresa tu código de confirmación",
	"sent_to_you_via_sms": "enviado a través de SMS !",
	"skip": "Saltear",
	"add_money_to_your_wallet_for": "Agregar dinero a tu billetera virtual por",
	"quick_payment": "pago rápido",
	"card_number": "Número de Tarjeta",
	"expiry_date": "Fecha de Expiración",
	"cvv_code": "Código de la Tarjeta",
	"inter_amout_in": "Ingresar monto (en $)",
	"referral_code": "¿Tienes un código de referencia?",
	"add_referral_code": "Agregar código de referencia y",
	"first_cab_ride_free": "obtén tu primer viaje con descuento.",
	"i_dont_have": "No tengo",
	"continue": "Continuar",
	"add_6_digit_referral_code": "Ingresa tu código de referencia de 6 digitos",
	"from": "Desde",
	"to": "Hasta",
	"tab_to_add_location": "Tap para agregar locación",
	"your_location": "Tu localización",
	"wallet": "Billetera Virtual",
	"movement": "Movimiento",
	"movements": "Movimientos",
	"cash": "Efectivo",
	"choose_cab_type": "Elige el tipo de móvil",
	"request_cab": "Solicitar vehículo",
	"schedule_pick_up": "Agendar solicitud de vehículo",
	"select_date": "Seleccionar Fecha",
	"date": "Fecha",
	"select_time": "Seleccionar Hora",
	"cancel": "Cancelar",
	"confirm": "Confirmar",
	"confirm_trip": "¿Confirmar Viaje?",
	"finding_your_ride": "Buscando Tu Viaje",
	"driver_info": "Información del Chófer",
	"cancel_ride": "Cancelar Viaje",
	"arriving_in": "Llegando en",
	"contact": "Contacto",
	"trip_info": "Información del Viaje",
	"distance": "Distancia",
	"traveling_time": "Tiempo de recorrido",
	"arrival": "Arribo",
	"goBack": "Volver",
	"pay": "Pagar",
	"payment": "Pago",
	"receipt": "Recibo",
	"fare_summary": "Resumen de Tarifas",
	"cash_pay": "Pagar en Efectivo",
	"wallet_pay": "Pago con Billetera Virtual",
	"choose_payment_mode": "Cambiar método de pago",
	"submit_rating": "Calificar y pagar",
	"rate_now": "Calificar ahora",
	"view_profile": "Ver Perfil",
	"home": "Inicio",
	"my_trips": "Mis viajes",
	"help": "Ayuda",
	"blog": "Blog",
	"refer_and_earn": "Referir y ganar",
	"logout": "Cerrar Sesión",
	"contact_number": "Telefóno",
	"your_referral_code": "Tu Código de Referencia",
	"referral_code_meassage": "Comparte este código de referencia y obtén un 30% de descuento en tu próximo viaje",
	"past": "Pasado",
	"upcoming": "Próximamente",
	"balance": "Balance",
	"available_balance": "Saldo Disponible",
	"send_to_bank": "Enviar a tu Banco",
	"add_money": "+ Agregar dinero",
	"enter_bank_info": "Ingrese Información del Banco",
	"account_number": "Nº de Cuenta",
	"account_holder_name": "Titular de la Cuenta",
	"bank_code": "CBU",
	"enter_amount_to_transfer": "Ingrese monto a transferir",
	"enter_amount_to_transfer_in": "Ingrese monto a transferir (en $)",
	"proceed": "Proceda",
	"trips_and_fare": "Viajes y Tarifas",
	"choose_your_issue": "Elige tu inconveniente",
	"any_issue_regarding_your_trip_and_fare": "Inconvenientes con tus viajes o tarifas",
	"problem_while_paying_fare_or_related_issue": "Problemas con tus pagos",
	"app_usability": "Usabilidad de la Aplicación",
	"any_issue_while_using_our_app": "Problemas para usar la apicación",
	"account": "Cuenta",
	"your_account_info_can_t_change_details_or_change_password": "La información de tu cuenta no se puede modificar"

}
