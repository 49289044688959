import React, {Component} from 'react';

import {BrowserRouter as Router, Route, Switch} from "react-router-dom";

import Rodal from 'rodal';

import './includes/build/styles/home.css';
import './includes/build/styles/ie_workaround.css';
import './includes/build/styles/teatro_broadway.css';
import 'react-inner-image-zoom/lib/InnerImageZoom/styles.min.css';
import './App.css';

import EventsPage from "./pages/events";
import EventPage from "./pages/event";
import PrivacyPage from "./pages/privacy";
import TermsPage from "./pages/terms";
import LoginPage from "./pages/login";
import RegisterPage from "./pages/register";
import DashboardPage from "./pages/dashboard";
import ProfilePage from "./pages/profile";
import RecoveryPage from "./pages/recovery";
import CongratsPage from "./pages/congrats";
import CheckoutPage from "./pages/checkout";
import MantenainceMode from "./components/mantenainceMode";

class App extends Component {

    constructor(props) {
        super(props);
        this.state = {
            visible: false,
            modalTitle: '',
            modalContent: '',
            loadingConfig: true
        };
    }

    componentWillMount() {
        const stylesArray= [
            'background-image: url(https://woopi.com.ar/images/logo-googleapps.png)',
            'background-size: 20%',
            'background-position: right center',
            'background-repeat: no-repeat',
            'background-color: #29DEEC',
            'color: white',
            'padding: 20px 100px 20px 10px',
            'line-height: 35px',
            'width : 70px',
            'height : 70px',
            'text-shadow : 1px 2px 2px black',
            'border : 5px solid black' ].join(';');
        console.log('%c Powered by EventTicket & Developed By Woopi.com.ar', stylesArray);
    }

    /* MODAL Functions */
    showModal(title, content) {
        this.setState({modalTitle: title});
        this.setState({modalContent: content});
        this.show();
    }

    show() {
        this.setState({visible: true});
    }

    hide() {
        this.setState({visible: false});
    }

    //

    render() {
        return (
            <Router>
                {process.env.REACT_APP_ENV_MANTEINANCE_MODE === 'true' ?
                    <MantenainceMode />
                    :
                    <Switch>
                        <Route path="/event/:id/" render={
                            (props) => <EventPage {...props}
                                                  showMainModal={(title, content) => this.showModal(title, content)}
                            />
                        }/>
                        <Route exact path="/privacy" render={
                            (props) => <PrivacyPage {...props}
                                                    showMainModal={(title, content) => this.showModal(title, content)}
                            />
                        }/>
                        <Route exact path="/terms" render={
                            (props) => <TermsPage {...props}
                                                  showMainModal={(title, content) => this.showModal(title, content)}
                            />
                        }/>
                        <Route exact path="/dashboard" render={
                            (props) => <DashboardPage {...props}
                                                      showMainModal={(title, content) => this.showModal(title, content)}
                            />
                        }/>
                        <Route exact path="/profile" render={
                            (props) => <ProfilePage {...props}
                                                    showMainModal={(title, content) => this.showModal(title, content)}
                            />
                        }/>
                        <Route exact path="/login" render={
                            (props) => <LoginPage {...props}
                                                  showMainModal={(title, content) => this.showModal(title, content)}
                            />
                        }/>
                        <Route exact path="/register" render={
                            (props) => <RegisterPage {...props}
                                                     showMainModal={(title, content) => this.showModal(title, content)}
                            />
                        }/>
                        <Route exact path="/recovery" render={
                            (props) => <RecoveryPage {...props}
                                                     showMainModal={(title, content) => this.showModal(title, content)}
                            />
                        }/>
                        <Route exact path="/checkout/:id" render={
                            (props) => <CheckoutPage {...props}
                                                     showMainModal={(title, content) => this.showModal(title, content)}
                            />
                        }/>
                        <Route exact path="/congrats/:status" render={
                            (props) => <CongratsPage {...props}
                                                     showMainModal={(title, content) => this.showModal(title, content)}
                            />
                        }/>
                        <Route exact path="*" render={
                            (props) => <EventsPage {...props}
                                                   showMainModal={(title, content) => this.showModal(title, content)}
                            />
                        }/>
                    </Switch>
                }

                <Rodal width={window.screen && window.screen.availWidth ? window.screen.availWidth * 0.7 : '300'}
                       visible={this.state.visible} onClose={() => this.hide()}>
                    <h4 className="rodal-title">{this.state.modalTitle}</h4>
                    <div className="rodal-body">{this.state.modalContent}</div>
                    <div className="rodal-footer">
                        <button className="btn btn-primary" type="button"
                                onClick={() => this.hide()}>Aceptar
                        </button>
                    </div>
                </Rodal>
            </Router>
        );
    }
}

export default App;
