import React, {Component} from 'react';
import APIService from "../../modules/apiService";
import DateTimeService from "../../modules/DateTimeService";
import locales_es from "../../locales/es";
import './styles.css';
import Spinner from "../spinner";
import Helpers from "../../modules/helpers";

class Review extends Component {

    constructor(props) {
        super(props);

        this.state = {
            performanceName: '',
            totalPrice: '',
            loading: false,
            hasCoupons: null,
        };

        this.api = new APIService();
        this.dateTimeService = new DateTimeService();
        this.helpers = new Helpers();
    }

    componentDidMount() {
        if (!this.props.performanceName) {
            this.api.getPerformance(this.props.performanceId).then(res => {
                const perf = res.data;
                this.setState({
                    performanceName: this.dateTimeService.parseEventDate(perf.date, false, 'full-string')
                        + ' '
                        + this.dateTimeService.parseEventTime(perf.date, 'full-string'),
                });
            }).catch(err => {
                alert(err.message);
            });
        }

        const totalPrice = Number((this.props.sectorPrice * this.props.objData.seatsIds.length)
            + (this.props.objData.seatsIds.length * Number(this.props.serviceCharge)))
            .toFixed(2);
        this.setState({
            totalPrice
        });

        this.checkIfHasCoupons();
    }

    checkIfHasCoupons() {
        if (this.props.eventId) {
            this.api.checkCoupons(this.props.eventId).then(() => {
                this.setHasCoupons(true);
            }).catch(err => {
                this.setHasCoupons(false);
            });
        }
    }

    setHasCoupons(bool) {
        this.setState({
            hasCoupons: bool
        })
    }

    setLoading(bool) {
        this.setState({
            loading: bool
        })
    }

    renderSeats() {
        const _seatsIds = JSON.parse(JSON.stringify(this.props.objData.seatsIds));
        return (
            _seatsIds.map(seat => {
                const str = ' | ' + seat.name;
                return (
                    // ' | ' + seat.name + ' | '
                    !_seatsIds.includes(str) && str
                )
            })
        )
        // return (' | ' + this.props.objData.seatsIds[0].name);
    }

	sendCoupon() {
    	if(!this.state.coupon) {
    		this.props.showMainModal(locales_es.errorModal.title, locales_es.errorModal.enterACoupon);
    		return;
		}
    	this.setLoading(true);
        this.api.getCoupon(this.props.eventId, this.state.coupon).then(res => {
            if (res && res.data) {
                this.setState({
                    discount: Number(res.data.value) / 100
                })
            }
            this.setLoading(false);
        }).catch(err => {
            this.props.showMainModal(locales_es.errorModal.title, this.helpers.getErrorMsg(err));
            this.setState({
                discount: null
            });
            this.setLoading(false);
        });
	}

	cleanCoupon() {
    	this.setState({
			discount: null,
			coupon: ''
		})
	}

    render() {
    	const {discount, coupon, loading, hasCoupons} = this.state;

        return (
            <div className="container">
                <div className="row event-profile__subheader">
                    <h1>Resumen de la compra</h1>
                </div>
                <div className="row">
                    <div className="col-12 col-md-6">
                        <div className="eventticket-review-image-wrapper">
                            <br/>
                            <img src={this.props.image}/>
                        </div>
                    </div>
                    <div className="col-12 col-md-6">
                        <div className="eventticket-review-content">
                            <h1>{this.props.title}</h1>
                        </div>
                        <div className="eventticket-review-content primary-color">
                            {
                            this.props.performanceName
                              ? this.props.performanceName
                              : this.state.performanceName
                                ? <h1><strong>{this.state.performanceName}</strong></h1>
                                : <Spinner/>
                            }
                        </div>
                        {this.props.theater ?
                            <>
                                <p className="eventticket-review-content__smaller-text">{this.props.theater.name} </p>
                                <p className="eventticket-review-content__smaller-text">{this.props.theater.address}</p>
                            </>
                            : null}

                        <p>{this.props.objData.seatsIds.length} Ticket{this.props.objData.seatsIds.length > 1 ? 's' : ''} x
                            ${this.props.sectorPrice} {this.renderSeats()}</p>

                        <div className="row eventticket-review-total_price">
                            <div className="col-xs-6" data-i18n="checkout.price">Precio:</div>
                            <div
                                className="text-right"> ${this.props.sectorPrice} x {this.props.objData.seatsIds.length}
                            </div>
                        </div>
                        <div className="row eventticket-review-total_price">
                            <div className="col-xs-6" data-i18n="checkout.price">Service Charge:</div>
                            <div
                                className="text-right"> ${Number(this.props.objData.seatsIds.length * Number(this.props.serviceCharge)).toFixed(2)}
                            </div>
                        </div>
                        <br/>
                        {hasCoupons && <>
                            <div className="row eventticket-review-total_price">
                                <div className="col-xs-8">
                                    <label>Cupón de descuento{discount && coupon ? ' (' + coupon + ')' : null}:</label>
                                </div>
                                <div className="col-xs-4 text-right">
                                    <strong>{discount ? `-${discount * 100}%` : ''}</strong>
                                </div>
                            </div>
                            <div className="row">
                                <div className="col-xs-12 col-md-9 padding-sm">
                                    {!discount &&
                                    <div className="input-group">
                                        <div className="input-group-addon">
                                            <i className={discount ? 'fa fa-check-circle-o' : 'fa fa-times-circle-o'}
                                               aria-hidden="true"/>
                                        </div>
                                        <input className="form-control"
                                               type="text"
                                               onChange={(e) => {
                                                   this.setState({
                                                       coupon: e.target.value
                                                   })
                                               }}
                                               placeholder="Ingresa tu código de descuento"
                                               value={coupon}
                                        />
                                    </div>
                                    }
                                </div>
                                <div className="col-xs-12 col-md-3 text-center text-md-right">
                                    {loading ?
                                        <Spinner/>
                                        : coupon &&
                                        <>
                                            {discount ?
                                                <button onClick={() => {
                                                    this.cleanCoupon()
                                                }}
                                                        className="btn btn-warning">Quitar cupón
                                                </button>
                                                :
                                                <button onClick={() => {
                                                    this.sendCoupon()
                                                }}
                                                        className="btn btn-warning">Aplicar cupón
                                                </button>
                                            }
                                        </>
                                    }
                                </div>
                            </div>
                        </>
                        }
                        <br/>
                        <div className="row eventticket-review-total_price">
                            <div className="col-xs-6" data-i18n="checkout.price">
                                {discount ? 'SUBTOTAL' : 'TOTAL'}:
                                {discount ? <><br />TOTAL</> : null}
                            </div>
                            <div className="text-right">
								<span className={discount ? 'discount-tag' : ''}> ${this.state.totalPrice}</span>
                            </div>
							{discount &&
                            	<div className="text-right"> ${this.state.totalPrice * (1 - discount)}</div>
							}
                        </div>
                        <br/>
                        <div className="row text-center">
                            <button onClick={() => this.props.confirm(this.state.totalPrice, discount ? coupon : null)}
                                    className="btn btn-primary">{discount ? 'Finalizar compra con descuento' : 'Finalizar compra'}
                            </button>
                        </div>
                    </div>
                </div>
            </div>
        )
    }
}

export default Review;
