import React, {Component} from 'react';
import Header from "../../components/header";
import Loading from "./../../components/loading";
import {withFooter} from "../../components/footerHOC";
import APIService from './../../modules/apiService';
import DateTimeService from "../../modules/DateTimeService";

import AuthService from "../../modules/authService";
import Helpers from "../../modules/helpers";
import locales_es from "../../locales/es";
import RegisterOrLoginRequest from "../../components/registerOrLoginRequest";
import Spinner from "../../components/spinner";
import {
  hrefCongratsSuccess,
  MERCADO_PAGO_BASIC,
  SECTOR_TYPE_NUMBERED,
  SECTOR_TYPE_QTY,
  STRIPE
} from "../../models/constants";
import InnerImageZoom from "react-inner-image-zoom";
import Review from "../../components/review";
import StripeCheckout from "../../components/checkout/stripeCheckout";
import NumberedMap from "../../components/numberedMap";
import AreasMap from "../../components/areasMap";

class CheckoutAreaMapPage extends Component {

  constructor(props) {
    super(props);
    this.state = {
      loading: false,
      checkout: null,
      event: this.props.eventData,
      performances: null,
      performanceId: '',
      eventId: Number(props.match.params.id),
      areas: null,
      areaId: '',
      seatType: null,
      sectors: null,
      sectorId: '',
      seats: null,
      seatsIds: '',
      quantity: 0,
      selectedAreaImage: '',
      showReview: false,
    };

    this.api = new APIService();
    this.dateTimeService = new DateTimeService();
    this.auth = new AuthService();
    this.helpers = new Helpers();

    this.confirm = this.confirm.bind(this);
    this.confirmNumbered = this.confirmNumbered.bind(this);
    this.maxSafeQuantity = 10;
  }

  componentDidMount() {
    this.checkAuth();
  }

  checkAuth() {
    // This check if for not to misuse API calls with not registered or logged in users
    if (!this.auth.isLoggedUser()) {
      window.showModal();
    } else {
      this.getPerformances();
    }
  }

  successMethod() {
    window.hideModal();
    this.setLoading(true);
    this.load();
  }

  setLoading(bool) {
    this.setState({
      loading: bool
    })
  }

  load() {
    this.getPerformances();
  }

  getPerformances() {
    this.api.getPerformances(this.state.eventId)
      .then((res) => {
        const data = res.data.filter(perf => this.dateTimeService.getTimeRemaining(perf.date).total > 0);
        this.setState({
          performances: data,
        }, () => {
          const fakeEvent = {
            target: {
              value: data[0].id
            }
          };
          this.onChangePerformance(fakeEvent);
        });
      }).catch(err => {
      this.props.showMainModal(locales_es.errorModal.title, err.message)
    });
  }

  onChangePerformance(e) {
    const perfId = e.target.value;
    const selectedPerformance = this.state.performances.filter(perf => {
      return Number(perf.id) === Number(perfId)
    })[0];

    this.setState({
      performanceName: `${this.dateTimeService.parseEventDate(selectedPerformance.date, false, 'full-string')} ${this.dateTimeService.parseEventTime(selectedPerformance.date, 'full-string')}`,
      performanceId: perfId,
      areaId: '',
      seatsIds: '',
      selectedPerformanceForReviewRenderingOnly: selectedPerformance,
    }, () => {
      this.getAreas();
      this.getSectors();
    });
  }

  getAreas() {
    this.setLoading(true);
    this.setState({
      areas: null,
    }, () => {
      this.api.getAreasAsSVG(this.state.performanceId)
        .then((res) => {
          this.setState({
            areas: res.data,
          }, () => {
            this.setLoading(false);
          });
        }).catch(err => {
        this.props.showMainModal(locales_es.errorModal.title, err.message)
      });
    });
  }

  onChangeArea(area) {
    if (!this.state.apiAreas) {
      this.setState({
        apiAreas: JSON.parse(JSON.stringify(this.state.areas)),
      })
    }
    this.setState({
      areas: null,
      seatType: null,
    }, () => {
      this.setState({
        areas: 0,
        areaId: area.id,
        selectedAreaImage: area.fullReferenceImage,
        selectedArea: {
          id: area.id,
          name: area.name,
        },
        seatType: area.seatType,
        // seatType: SECTOR_TYPE_NUMBERED,
      });
    })
  }

  handleChangeQuantity(e) {
    const inputValue = e.target.value;
    this.setState({
      quantity: inputValue
    }, () => this.setQuantitySeatsIds());
  }

  setQuantitySeatsIds() {
    // const area = this.state.areas.filter(s => Number(s.id) === Number(this.state.areaId))[0];
    // console.log(this.state.selectedArea);
    const manualSeatsId = [];
    for (let i = 0; i < this.state.quantity && i < this.maxSafeQuantity; i++) {
      // manualSeatsId.push(this.state.seats[i]);
      /*manualSeatsId.push({
        id: String(new Date().getTime()) + String(i),
        name: area.name
      });*/
      manualSeatsId.push({
        id: String(new Date().getTime()) + String(i),
        name: this.state.selectedArea.name
      });
    }
    this.setState({
      seatsIds: manualSeatsId
    });
  }

  validateForm() {
    if (!this.state.performanceId) {
      this.props.showMainModal(locales_es.errorModal.title, 'Elija la función a la que quiere asistir');
      return false;
    }

    if (!this.state.areaId) {
      this.props.showMainModal(locales_es.errorModal.title, 'Elija el area deseada');
      return false;
    }

    if (this.state.seatType === SECTOR_TYPE_QTY && !this.state.quantity) {
      this.props.showMainModal(locales_es.errorModal.title, 'Elija la cantidad de tickets deseada');
      return false;
    } else if (this.state.seatType === SECTOR_TYPE_QTY && this.state.quantity > this.maxSafeQuantity) {
      this.props.showMainModal(locales_es.errorModal.title, `No se pueden adquirir más de ${this.maxSafeQuantity} tickets`);
      return false;
    }

    if (!this.state.seatsIds || !this.state.seatsIds.length) {
      this.props.showMainModal(locales_es.errorModal.title, 'Elija al menos un asiento');
      return false;
    }

    return true;
  }

  send() {
    if (this.validateForm()) {
      const objData = {
        performanceId: this.state.performanceId,
        areaId: this.state.areaId,
        seatsIds: this.state.seatsIds,
      };
      this.setState({
        objData
      });
      if (this.state.seatType === SECTOR_TYPE_QTY) {
        // TODO levantar del array this.state.sectors que estoy levantando para mostrar los precios de todo
        this.api.getSectors(this.state.eventId, this.state.performanceId, this.state.areaId).then(res => {
          this.setState({
            sectorId: res.data.id,
            sectorPrice: res.data.price,
            serviceCharge: res.data.service_charge,
          }, () => {
            this.setReviewStatus(true);
          })
        }).catch(err => {
          this.props.showMainModal(locales_es.errorModal.title, this.helpers.getErrorMsg(err));
        });
      }
    }
  }

  setReviewStatus(bool) {
    this.setState({
      showReview: bool
    });
    this.props.history.push({
      search: `?review=${bool}`
    });
  }

  confirm(totalPrice) {
    this.api.getConfig().then(res2 => {
      this.setState({
        checkout: res2['payment-gateway'],
        stripePublicKey: res2['stripe-public-key'],
        totalPrice,
      }, () => {
        if (this.state.checkout === MERCADO_PAGO_BASIC) {
          this.goToMercadoBasic();
        }
      });
    }).catch(err => {
      this.props.showMainModal(locales_es.errorModal.title, locales_es.errorModal.apiGetConfigError);
    });
  }


  renderStripeCheckout() {
    const data = {
      event_id: this.state.eventId,
      event_performance_id: this.state.performanceId,
      area_id: this.state.areaId,
      seats_ids: this.getSeatsIds(),
    };

    const success = () => {
      this.props.showMainModal(locales_es.checkoutSuccessModal.title, locales_es.checkoutSuccessModal.subtitle);
      this.props.history.replace(hrefCongratsSuccess)
    };

    const error = () => {
      this.props.showMainModal(locales_es.checkoutErrorModal.title, locales_es.checkoutErrorModal.subtitle);
    };

    window.stripeKey = this.state.stripePublicKey;

    return (
      <StripeCheckout stripePublicKey={this.state.stripePublicKey} totalPrice={this.state.totalPrice} data={data}
                      onSuccess={success} onError={error}/>
    )
  }

  goToMercadoBasic() {
    const data = {
      event_id: this.state.eventId,
      event_performance_id: this.state.performanceId,
      sector_id: this.state.sectorId,
      seats_ids: this.getSeatsIds(),
    };

    this.api.postCheckout(data).then(res => {
      window.location.href = res.data.init_point;
    }).catch(err => {
      this.props.showMainModal(locales_es.errorModal.title, this.helpers.getErrorMsg(err));
    });
  }

  cancelCheckout() {
    this.setReviewStatus(false);
    this.setState({
      checkout: null,
    });
    this.clearSector();
  }

  getSeatsIds() {
    return this.state.seatsIds.map(seat => {
      return seat.id;
    });
  }

  confirmNumbered(seatsIds, totalPrice, sectorId, sectorPrice, serviceCharge, sectorName) {
    const objData = {
      performanceId: this.state.performanceId,
      areaId: this.state.areaId,
      seatsIds: seatsIds,
    };
    this.setState({
      objData,
      seatsIds,
      sectorId,
      sectorPrice,
      serviceCharge,
      sectorName,
    }, () => {
      this.setReviewStatus(true);
    })
  }

  setSectorsLoading(bool) {
    this.setState({
      sectorLoading: bool
    });
  }

  getSectors() {
    this.setSectorsLoading(true);
    this.setState({
      sectors: null,
    });
    this.api.getSectors(this.state.eventId, this.state.performanceId)
      .then((res) => {
        this.setState({
          sectors: res.data,
        }, () => this.setSectorsLoading(false));
      }).catch(err => {
      this.props.showMainModal(locales_es.errorModal.title, err.message)
    });
  }

  renderSectors() {
    return (
      this.state.sectors && !this.state.sectorLoading ?
        this.state.sectors.map(sector => {
          return (
            <p key={sector.id}>
              <span className="eventticket-reference-color" style={{backgroundColor: sector.color}}/>
              {sector.name} {locales_es.currency_sign}
              {sector.final_price}
              {sector.sold ? ' (AGOTADO)' : ''}
            </p>
          )
        })
        : <Spinner/>
    )
  }

  clearSector() {
    const clearAreaObj = {
      id: null,
      selectedAreaImage: null,
      selectedArea: null,
      seatType: null,
      quantity: 0,
    };
    this.onChangeArea(clearAreaObj);
    this.setState({
      areas: null
    }, () => {
      this.setState({
        areas: this.state.apiAreas
      })
    });
  }

  render() {

    const {
      loading,
      event,
      performances,
      areas,
      selectedAreaImage,
      seatType,
      showReview,
      checkout,
    } = this.state;

    return (
      <div>
        {loading ? <Loading/> : null}
        <Header showMainModal={this.props.showMainModal}/>

        {!this.auth.isLoggedUser() ?
          <RegisterOrLoginRequest modalMode={true}
                                  successMethod={() => this.successMethod()}
                                  showMainModal={this.props.showMainModal}
                                  eventId={this.state.eventId}
                                  pathname={this.props.location.pathname}/>
          :
          checkout ?
            <div>
              <div className="container">
                <a onClick={() => this.cancelCheckout()}
                   data-i18n="registerLink"> {locales_es.goBack}</a>
              </div>
              {this.state.checkout === STRIPE ? this.renderStripeCheckout() : <Spinner/>}
            </div>
            :
            showReview && event ?
              <div className="padding-top-xl">
                <div className="container">
                  {/*<a onClick={() => this.setReviewStatus(false)}*/}
                  <a onClick={() => this.cancelCheckout()}
                     data-i18n="registerLink"> {locales_es.goBack}</a>
                </div>
                <Review image={event.full_image}
                        title={event.title}
                        theater={event.theater}
                        performanceId={this.state.performanceId}
                        objData={this.state.objData}
                        sectorPrice={this.state.sectorPrice}
                        serviceCharge={this.state.serviceCharge}
                        confirm={this.confirm}
                        performanceName={this.state.performanceName}
                />
              </div>
              : event ?
              <div>
                <div
                  className="event-profile__subheader event-profile__subheader-desktop hidden-xs hidden-sm">
                  <div className="container">
                    <div className="row"><h1 className="ng-binding">{event.title}</h1></div>
                  </div>
                </div>

                <div className="container">
                  <div className="row event-profile">
                    <div className="col-sm-12 col-md-6">
                        <h4 className="reference-labels__title"><strong
                          data-i18n="bookingMap.mainTitle">Selección de Asientos</strong></h4>
                        <div className="row">
                          <h4>Elegir función</h4>
                          {performances ?
                            <select
                              className="form-control"
                              onChange={(e) => this.onChangePerformance(e)}
                              value={this.state.performanceId}
                              required="required">
                              {performances.map(perf => {
                                return (
                                  <option value={perf.id} key={'perf' + perf.id}>
                                    {this.state.performanceName}
                                  </option>
                                )
                              })
                              }
                            </select>
                            :
                            <Spinner/>
                          }
                          <br/>
                        </div>
                      </div>

                    <div className="col-sm-12 col-md-6">
                      <h4 className="reference-labels__title">
                        <strong data-i18n="bookingMap.title">Referencias y Precios</strong>
                      </h4>
                      <div className="row">
                        <div className="col">
                          {this.renderSectors()}
                        </div>
                      </div>
                    </div>
                  </div>

                  <div className="row">
                    <div className="col-sm-12">
                      {areas === null ?
                        <Spinner />
                        : areas ?
                          <>
                            <h4 data-i18n="eventTicketsSelection.areasLabel">{locales_es.chooseArea}</h4>
                            <AreasMap
                              areas={areas}
                              showMainModal={this.props.showMainModal}
                              onAreaSelect={(area) => {
                                this.onChangeArea(area);
                              }}
                            />
                          </>
                        :
                        null
                      }
                      <br/>
                    </div>
                  </div>

                  <div className="row">
                    <div className="col-sm-12">
                      <h4 data-i18n="eventTicketsSelection.areasLabel">
                        {
                          seatType === null ?
                            '' :
                            seatType === SECTOR_TYPE_QTY
                              ? locales_es.selectTicketsQuantity
                              : locales_es.chooseSeats
                        }
                      </h4>
                      {
                        seatType === SECTOR_TYPE_QTY ?
                          <>
                            <a onClick={() => this.clearSector()}>{locales_es.back}</a>
                            <input className="form-control"
                                   type="number"
                                   min={1}
                                   max={10} // TODO API
                                   value={this.state.quantity}
                                   onChange={(e) => this.handleChangeQuantity(e)}/>
                          </>
                          :
                          null
                      }
                      {seatType === SECTOR_TYPE_NUMBERED ?
                        <div>
                          <NumberedMap
                            showMainModal={this.props.showMainModal}
                            performanceId={this.state.performanceId}
                            selectedPerformanceForReviewRenderingOnly={this.state.selectedPerformanceForReviewRenderingOnly}
                            area={this.state.selectedArea}
                            confirm={this.confirmNumbered}
                            goBack={() => {
                              this.cancelCheckout();
                              this.clearSector();
                            }}
                          />
                        </div>
                        : null
                      }
                      <br/>
                    </div>

                    {seatType === SECTOR_TYPE_QTY &&
                    <div className="row">
                      <button onClick={() => this.send()} type="button"
                              id="layout-btn-done"
                              disabled={!this.state.quantity}
                              className="btn btn-primary">
                        {locales_es.continue}
                      </button>
                    </div>
                    }
                  </div>

                  {/*<div className="row">
                    <div className="col hidden-md hidden-lg">
                      <div className="col-sm-12 col-md-6">
                      <h4 className="reference-labels__title">
                        <strong data-i18n="bookingMap.title">Referencias y Precios</strong>
                      </h4>
                      <div className="row">
                        <div className="col">
                          {this.renderSectors()}
                        </div>
                      </div>
                    </div>
                  </div>*/}
                  {selectedAreaImage ?
                    <div className="row">
                      <div className="col hidden-sm">
                        <h4 className="reference-labels__title">
                          <strong data-i18n="bookingMap.firstFloor">SECTOR ELEGIDO</strong>
                        </h4>
                        <div className="row">
                          <div className="col text-center">
                            <InnerImageZoom src={selectedAreaImage}
                                            zoomSrc={selectedAreaImage}/>
                          </div>
                        </div>
                      </div>
                    </div>
                    : null
                  }

                </div>


              </div>
              : null
        }
      </div>
    )
  }
}

export default withFooter(CheckoutAreaMapPage);
