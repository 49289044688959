import React, {Component} from 'react';
import Header from "../../components/header";
import Loading from "./../../components/loading";
import {withFooter} from "../../components/footerHOC";
import APIService from './../../modules/apiService';
import DateTimeService from "../../modules/DateTimeService";
import locales_es from "../../locales/es";
import Spinner from "../../components/spinner";
import AnalyticsService from "../../modules/analyticsService";

class EventPage extends Component {

    constructor(props) {
        super(props);
        this.state = {
            loading: false,
            event: null,
            performances: null,
            eventId: Number(props.match.params.id),
        };

        this.api = new APIService();
        this.dateTimeService = new DateTimeService();
        this.analyticsService = new AnalyticsService({
            eventId: this.state.eventId,
            pathname: this.props.location.pathname
        });
    }

    componentDidMount() {
        this.api.getEvents(this.state.eventId)
            .then((res) => {
                this.setState({
                    event: res.data,
                }, () => {
                    this.getPerformances();
                });
            }).catch(err => {
            this.props.showMainModal('Error', err.message)
        });

        this.analyticsService.initFacebook();
        this.analyticsService.initGTM().then(() => {
            this.analyticsService.sendGTMEvent('conversion','loadEvent');
        }).catch(err => {
            console.error(err);
        });
    }

    getPerformances() {
        this.api.getPerformances(this.state.eventId)
            .then((res) => {
                this.setState({
                    performances: res.data,
                });
            }).catch(err => {
            this.props.showMainModal('Error', err.message)
        });
    }

    renderPerformances() {
        let i = 0;
        let hasPerformances = 0;
        return (
            this.state.performances.map(performance => {
                i += 1;
                if (this.dateTimeService.getTimeRemaining(performance.date).total > 0) {
                    hasPerformances++;
                    return (
                        <div key={'perf-' + i} className="eventticket--profile-performances">
				  <span dangerouslySetInnerHTML={
                      {
                          __html: this.dateTimeService.parseEventDate(performance.date)
                      }
                  }/>
                            <span dangerouslySetInnerHTML={
                                {
                                    __html: this.dateTimeService.parseEventTime(performance.date)
                                }
                            }/>
                        </div>
                    )
                }
                if (i === this.state.performances.length && !hasPerformances) {
                    return this.renderNoPerformances();
                }
            })
        )
    }

    renderNoPerformances() {
        return <p key="no-perf">{locales_es.noPresentationAvailable}</p>
    }

    renderBuyButton() {
        let i = 0;
        let hasPerformances = 0;
        this.state.performances.map(performance => {
            i += 1;
            if (this.dateTimeService.getTimeRemaining(performance.date).total > 0) {
                hasPerformances++;
            }
        });
        if (hasPerformances) {
            return (
                <button id="ticketsBtn"
                        onClick={() => this.goToEvent()}
                        className="btn btn-primary btn-lg text-uppercase">
                    {locales_es.buy}
                </button>
            )
        } else {
            return (
                <button id="ticketsBtn"
                        onClick={() => alert(locales_es.noStock)}
                        disabled={true}
                        className="btn btn-primary btn-lg text-uppercase">
                    {locales_es.noStock}
                </button>
            )
        }
    }

    goToEvent() {
        this.props.history.push('/checkout/' + this.state.eventId);
    }

    render() {
        const {event, performances} = this.state;

        return (
            <div>
                {this.state.loading ? <Loading/> : ''}
                <Header showMainModal={this.props.showMainModal}/>

                <div className="row padding-top-xl">
                    <div className="container">
                        {
                            event == null ? <Loading/>
                                : event ?

                                <div className="row event-profile">
                                    {event.status === 'paused' ?

                                        <div className="row">
                                            <div className="alert alert-dismissible alert-warning">
                                                <button type="button" className="close"
                                                        data-dismiss="alert">&times;</button>
                                                <h4>Evento Pausado</h4>
                                                <p>Este evento no es público. Sólo
                                                    tú puedes verlo. Usa esta vista como borrador para previsualizar el
                                                    evento.
                                                    Cuando estés listo, retorna al Panel de Control e Inicia el evento
                                                    desde
                                                    las
                                                    Acciones del mismo.</p>
                                            </div>
                                        </div> :

                                        <div className="row">
                                            <div className="col-sm-5">
                                                <div className="well event-profile-data">
                                                    <img src={event.full_image}
                                                         className="img-responsive event-profile-img"/>


                                                    <div className="event-profile-data__description">
                                                        <div className="tcenter" style={{marginTop: '20px'}}>
                                                            {performances && performances.length ?
                                                                this.renderBuyButton()
                                                                :
                                                                <Spinner/>
                                                            }
                                                        </div>
                                                        <h1
                                                            className="event-profile-data__event-name hidden-sm hidden-md hidden-lg">
                                                            {event.title}
                                                        </h1>
                                                        <div className="tcenter event-profile-data__first-child">
                                                            <h2>Funciones</h2>
                                                            {performances === null ?
                                                                <Spinner/>
                                                                :
                                                                performances && performances.length ?

                                                                    this.renderPerformances()
                                                                    :
                                                                    this.renderNoPerformances()
                                                            }
                                                        </div>
                                                    </div>


                                                </div>
                                            </div>


                                            <div className="col-sm-7 event-profile-data__event-mobile-card-wrapper">
                                                <label
                                                    className="event-profile-data__event-description-label
											hidden-sm hidden-md hidden-lg">{event.title}</label>
                                                <h1 className="event-profile-data__event-name desktop-only
										  visible-sm visible-md visible-lg"
                                                    ng-show="browser.desktop">{event.title}</h1>
                                                <div className="event-profile-data__event-description lead"
                                                     dangerouslySetInnerHTML={
                                                         {
                                                             __html: event.description
                                                         }
                                                     }/>
                                            </div>


                                        </div>
                                    }

                                    {event.theater ?
                                        <div className="event-profile-data__event-mobile-card-wrapper__map">
                                            <div className="row event-profile-data__event-description text-left">
                                                <div
                                                    className="col tcenter">{event.theater.name} - {event.theater.address}
                                                </div>
                                            </div>
                                            <div className="event-profile-map">
                                                <div style={{width: '100%', height: '100%'}}>
                                                    <div dangerouslySetInnerHTML={{
                                                        __html: `
										  	<iframe width="100%" height="100%" frameBorder="0" scrolling="no"
											          marginHeight="0" marginWidth="0"
											          src="https://maps.google.com/maps?width=100%25&amp;height=600&amp;hl=en&amp;q=${event.theater.lat},${event.theater.lng}&hl=es&z=14&amp;output=embed"/>
										  `
                                                    }}
                                                    />
                                                </div>
                                            </div>
                                        </div>
                                        :
                                        null
                                    }

                                </div>
                                :
                                <div className="tcenter"> Ha ocurrido un error al cargar el evento. <br/><br/></div>
                        }
                    </div>
                </div>
            </div>
        )
    }
}

export default withFooter(EventPage);
