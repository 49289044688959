import React, {Component} from 'react';
import locales_es from "../../locales/es";

export default class CheckoutReview extends Component {

	render() {

		const {
			seatsIds,
			choCartReviewVisible,
			sectorPrice,
			serviceCharge,
			date,
			areaName,
			sectorName,
			toggleChoCartReviewOrSet,
			clear,
			send
		} = this.props;

		return (
			<div className="eventticket-cho-cart-review">
				<div className="container">
					<div className="row">
						<div className="col-12">
							{seatsIds && seatsIds.length ?
								<>
									<div
										className="eventticket-cho-cart-review-toggle text-right pr-0 mr-0"
										onClick={toggleChoCartReviewOrSet}>
										{choCartReviewVisible ?
											<i className="fas fa-chevron-down"/>
											:
											<>
												<strong>{locales_es.orderReview}</strong>&nbsp;
												<i className="fas fa-chevron-up"/>
												<br/>
												<br/>
											</>
										}
									</div>
									{choCartReviewVisible ?
										<>
											<h3>
												<strong data-i18n="bookingMap.firstFloor">
													{seatsIds && seatsIds.length ? locales_es.orderReview : locales_es.selectedSeats}</strong>
											</h3>
											<div>
												{locales_es.performance}: <strong><i>{date}</i></strong>
											</div>
											<div>
												{locales_es.sector}: {areaName && <strong><i>{areaName}</i> - </strong>}<strong><i>{sectorName}</i></strong>
											</div>
											<div>
												<span>{locales_es.seatsPosition}: </span>
												<strong>
													<i>
														{
															seatsIds.map(seat => {
																return (
																	<span key={seat.id}
																		  className="eventticket-cho-cart-review--ticket">
																						{seat.name} - {locales_es.currency_sign}{seat.finalPrice}&nbsp;&nbsp;
																					</span>
																)
															})
														}
													</i>
												</strong>
											</div>
											<br/>
											<h4>
												<span>{locales_es.total}</span>: <strong> {locales_es.currency_sign} {(seatsIds.length * sectorPrice) + (seatsIds.length * serviceCharge)}</strong>
											</h4>
											<div><br/><br/></div>
										</>
										:
										null
									}
								</>
								:
								<div className="row">
									<div className="col text-center">
										<br/>
										<strong>{locales_es.noSelectedSeats}</strong>
										<br/>
										<br/>
									</div>
								</div>
							}
						</div>
					</div>
					<div className="row">
						<div className="col-xs-6 p-0">
							<button onClick={clear} type="button"
									className="btn btn-warning">
								{locales_es.clean}
							</button>
						</div>
						<div className="col-xs-6 text-right p-0">
							<button onClick={send} type="button"
									className="btn btn-primary">
								{locales_es.continue}
							</button>
						</div>
					</div>
				</div>
			</div>
		)
	}
}
