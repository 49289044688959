import React, {Component} from 'react';
import {
  mapFullScreenBtnClassName,
  mapZoomInBtnClassName,
  mapZoomOutBtnClassName,
  MAX_ZOOM_MOBILE,
  AREA_DISABLED_ATTR,
  AREA_FINAL_PRICE_ATTR,
  AREA_NAME_ATTR,
  seatSelectorId,
  AREA_ID_ATTR, AREA_SEAT_TYPE_ATTR, AREA_FULL_REFERENCE_IMAGE_ATTR,
} from "../../models/constants";
import locales_es from "../../locales/es";
import {eventsHandler} from "../../modules/mobileMapEventsHandler";
import svgPanZoom from "svg-pan-zoom";
import APIService from "../../modules/apiService";
import Helpers from "../../modules/helpers";
import DateTimeService from "../../modules/DateTimeService";


export default class AreasMap extends Component {
  constructor(props) {
    super(props);

    this.state = {
      setSvgLoading: false,
    };

    this.api = new APIService();
    this.helpers = new Helpers();
    this.dateTimeService = new DateTimeService();
  }

  componentDidMount() {
    this.initSvgMap();
  }

  initSvgMap() {
    const el = document.getElementById(seatSelectorId);
    if (el) {
      el.innerHTML = this.props.areas;
      this.loadSvgMap();
    } else {
      console.error('el not found');
    }
  }

  setSvgLoading(bool) {
    this.setState({
      setSvgLoading: bool
    })
  }

  loadSvgMap() {
    try {
      const options = {
        zoomEnabled: !this.helpers.isMobile(),
        controlIconsEnabled: !this.helpers.isMobile(),
        fit: true,
        center: true,
        minZoom: 1,
        maxZoom: this.helpers.isMobile() ? MAX_ZOOM_MOBILE : 3,
        dblClickZoomEnabled: false,
        customEventsHandler: this.helpers.isMobile() ? eventsHandler : undefined,
      };
      window.mapZoom = svgPanZoom('#mainSVG2', options);
      this.addAreaListeners();
      this.addMobileMapZoomListeners();
    } catch (e) {
      console.log(e);
      this.props.showMainModal(locales_es.errorModal.title, e.message);
    }
  }

  addMobileMapZoomListeners() {
    const map = window.document.getElementById(seatSelectorId);
    const zoomInBtn = window.document.querySelector(`.${mapZoomInBtnClassName}`);
    const zoomOutBtn = window.document.querySelector(`.${mapZoomOutBtnClassName}`);
    const fullscreenBtn = window.document.querySelector(`.${mapFullScreenBtnClassName}`);
    if (this.helpers.isMobile() && map && zoomInBtn) {
      window.isMobileZoomed = false;

      zoomInBtn.classList.add('show-inline');
      zoomInBtn.addEventListener('click', (e) => {
        if (!window.isMobileZoomed) {
          e.stopPropagation();
          window.isMobileZoomed = true;
          window.mapZoom.zoomBy(MAX_ZOOM_MOBILE);
          zoomOutBtn.classList.add('show-inline');
          zoomInBtn.classList.remove('show-inline');
          fullscreenBtn.classList.add('show-inline');
        }
      }, false);


      zoomOutBtn.addEventListener('click', () => {
        window.mapZoom.zoomBy(0);
        window.isMobileZoomed = false;
        zoomInBtn.classList.add('show-inline');
        zoomOutBtn.classList.remove('show-inline');
        fullscreenBtn.classList.remove('show-inline');
      }, false);

      fullscreenBtn.addEventListener('click', () => {
        map.classList.toggle('eventticket--fullscreen-svg');
        zoomOutBtn.classList.toggle('show-inline');
        // fullscreenBtn.classList.remove('show-inline');
      }, false);
    }
  }

  getAllAreasAsAnArray() {
    const map = window.document.getElementById(seatSelectorId);
    const areas = map.getElementsByTagName('rect');
    return Array.from(areas);
  }

  addAreaListeners() {
    window.isDragging = false;
    const areas = this.getAllAreasAsAnArray();

    const mobileDelayMove = 300;

    // convert to Array cause areas is an HTML Collection
    areas.map(area => {

      if (!this.helpers.isMobile()) {
        // DESKTOP
        area.addEventListener("mousedown", () => {
          window.isDragging = false;
        }, false);

        area.addEventListener("mousemove", () => {
          window.isDragging = true;
        }, false);

        area.addEventListener("mouseup", () => {
          if (!window.isDragging) {
            this.onAreaSelect(area);
          }
        }, false);

      } else {
        // MOBILE
        area.addEventListener("touchstart", () => {
          window.isDragging = false;
          window.draggingLapse = new Date();
        }, false);

        area.addEventListener("touchmove", () => {
          if ((new Date() - window.draggingLapse) > mobileDelayMove) {
            window.isDragging = true;
          }
        }, false);

        area.addEventListener("touchend", (e) => {
          e.preventDefault(); // Woopi: problema que hacía que no se disparara bien el modal de error
          if (!window.isDragging) {
            this.onAreaSelect(area);
          }
        }, false);
      }

      // TOOLTIP
      let areaStr = locales_es.area
        + ': '
        + area.getAttribute(AREA_NAME_ATTR);

      if (area.getAttribute(AREA_FINAL_PRICE_ATTR)) {
        areaStr = ' - '
        + locales_es.price
        + ': '
        + locales_es.currency_sign
        + area.getAttribute(AREA_FINAL_PRICE_ATTR);
      }
      area.setAttribute('data-toggle', 'tooltip');
      area.setAttribute('data-placement', 'top');
      area.setAttribute('title', areaStr);
    });
    window.$('[data-toggle="tooltip"]').tooltip({'container': 'body',})
  }

  onAreaSelect(area) {
    if (area.getAttribute(AREA_DISABLED_ATTR) === 'true') {
      this.props.showMainModal(locales_es.infoModal.title, locales_es.infoModal.disabledArea);
      return;
    }
    const areaObj = {
      id: area.getAttribute(AREA_ID_ATTR),
      name: area.getAttribute(AREA_NAME_ATTR),
      seatType: area.getAttribute(AREA_SEAT_TYPE_ATTR),
      fullReferenceImage: area.getAttribute(AREA_FULL_REFERENCE_IMAGE_ATTR),
    };
    console.log(area);
    window.$('[data-toggle="tooltip"], .tooltip').tooltip("hide");
    this.props.onAreaSelect(areaObj);
  }

  render() {
    return (
      <>
        <div className="eventticket-cho-map">
          <div id={seatSelectorId} style={{overflow: 'hidden'}}/>
        </div>
      </>
    )
  }
}
