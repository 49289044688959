import React, {Component} from 'react';
import Header from "../../components/header";
import Loading from "./../../components/loading";
import {withFooter} from "../../components/footerHOC";
import APIService from './../../modules/apiService';

class EventsPage extends Component {

	constructor(props) {
		super(props);
		this.state = {
			loading: false,
			events: null,
		};

		this.api = new APIService();
	}

	componentDidMount() {
		this.api.getEvents()
		  .then((res) => {
			  this.setState({
				  events: res.data.filter(event => event.enabled),
			  }, () => {
			  	if (this.state.events && this.state.events.length && this.state.events.length === 1) {
					this.props.history.push('/event/' + this.state.events[0].id);
				}
			  });
		  }).catch(err => {
			this.props.showMainModal('Error', err.message)
		});
	}

	renderEvents() {
		let i = 0;
		return (
		  this.state.events.map(event => {
			  i += 1;
			  return (
				<li key={i} className="all-events-profiles__box"
				    style={{backgroundImage: `url(${event.full_image})`, backgroundPosition: 'center'}}>
					<a href={`/event/${event.id}`}>
						<div className="all-events-profiles__box-event-container">
							<div className="all-events-profiles__box-event-name">{event.title}</div>
						</div>
					</a></li>
			  )
		  })
		)
	}

	render() {

		const {events} = this.state;

		return (
		  <div>
			  {this.state.loading ? <Loading/> : ''}
			  <Header showMainModal={this.props.showMainModal}/>

			  <div className="row padding-top-xl">
				  <div className="container">
					  <h2 className="sub-header">Eventos</h2>
					  {
						  events == null ? <Loading/>
							: events && events.length ?
							<ul className="all-events-profiles">
								{this.renderEvents()}
							</ul>
							:
							<div className="tcenter"> No hay eventos disponibles... <br/><br/></div>
					  }
				  </div>
			  </div>
		  </div>
		)
	}
}

export default withFooter(EventsPage);
